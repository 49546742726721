import React from 'react';
import {Container} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import './styles.css'


const Phases = () => {
    return (
        <div className="phases">
            {/*<Container maxWidth={false} sx={{maxWidth: "1284px"}}>*/}
                <h2 className="block-title">Phases</h2>
                <div className="phases-container">

                    <Swiper
                        grabCursor={true}
                        loop={false}
                        // centeredSlides={true}
                        slidesPerView={3.2}
                        spaceBetween={-20}
                        pagination={{el: '.swiper-pagination', clickable: true}}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation]}
                        className="my_swiper_container_phases"

                        breakpoints={{
                            // when window width is >= 320px
                            320: {
                                slidesPerView: 1.1,
                                spaceBetween: 10
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 1.3,
                                spaceBetween: 10
                            },
                            // when window width is >= 740px
                            540: {
                                slidesPerView: 1.4,
                                spaceBetween: 10
                            },
                            640: {
                                slidesPerView: 1.8,
                                spaceBetween: 10
                            },
                            740: {
                                slidesPerView: 2.2,
                                spaceBetween: 10
                            },
                            900: {
                                slidesPerView: 2.5,
                                spaceBetween: 20
                            },
                            1040: {
                                slidesPerView: 2.8,
                                spaceBetween: 20
                            },
                            1240: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            },
                            1300: {
                                slidesPerView: 3.3,
                                spaceBetween: 20
                            },
                            1440: {
                                slidesPerView: 3.5,
                                spaceBetween: 20
                            },

                            1550: {
                                slidesPerView: 4.5,
                                spaceBetween: 20
                            },
                            2000: {
                                slidesPerView: 5,
                                spaceBetween: 20
                            },

                        }

                        }

                    >

                        <SwiperSlide>
                            <div className="phase-box phase1">
                                <p className="phase-number white">Phase 1 </p>
                                <h3 className="phase-title white">Discovery</h3>
                                <p className="phase-text white">Our team will perform research and discovery to plan and
                                    inform the phases of the project.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="phase-box phase2">
                                <p className="phase-number light-grey">Phase 2 </p>
                                <h3 className="phase-title white">Design</h3>
                                <p className="phase-text white">Our team will perform research and discovery to plan and
                                    inform the phases of the project.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="phase-box phase3">
                                <p className="phase-number white">Phase 3 </p>
                                <h3 className="phase-title white">Development</h3>
                                <p className="phase-text white">Our team will perform research and discovery to plan and
                                    inform the phases of the project.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="phase-box phase4">
                                <p className="phase-number light-grey ">Phase 4 </p>
                                <h3 className="phase-title red">Testing</h3>
                                <p className="phase-text ">Our team will perform research and discovery to plan and
                                    inform the phases of the project.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="phase-box phase5">
                                <p className="phase-number white">Phase 5 </p>
                                <h3 className="phase-title white">Maintenance</h3>
                                <p className="phase-text white">Our team will perform research and discovery to plan and
                                    inform the phases of the project.</p>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            {/*</Container>*/}
        </div>
    );
};

export default Phases;