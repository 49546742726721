import React from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";


import stepBar from '../../../assets/images/plannin-step-bar.svg'

const ElmartPlanning = () => {
    const max730 = useMediaQuery({maxWidth: 730})

    return (
        <div className="planning-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h3 className="planning-title" style={max730 ? {marginBottom:"36px", fontSize:"38px"} : {}}>Planning</h3>
                <div style={max730 ? {display: "flex", flexDirection: "row-reverse"} : {}}>
                    {max730 ? (
                        <div className="vertical-gradient">
                            <div className="gradient-circle gradient-circle-1"></div>
                            <div className="gradient-circle gradient-circle-2"></div>
                            <div className="gradient-circle gradient-circle-3"></div>
                        </div>
                    ) : (
                        <img src={stepBar} alt="step-bar" className="planning-step-bar"/>
                    )}

                    <Grid container>
                        <Grid item xs={12} sm1={3} style={{marginBottom: "32px", marginRight: "50px"}}>
                            <h2 className="planning-title">Incoming info</h2>
                            <p className="planning-text">Client had ideas on:</p>
                            <ul className="white-dots-list">
                                <li>Business model</li>
                                <li>Initial concept</li>
                                <li>Feature breakdown list</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm1={3.3} style={{marginBottom: "32px", marginRight: "60px"}}>
                            <h2 className="planning-title">Analysis</h2>
                            <p className="planning-text">We analyze the client’s requirements:</p>
                            <ul className="white-dots-list">
                                <li>Dedicated a Business Analyst</li>
                                <li>Around 20 meetings to clarify the requirements</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm1={3.5} style={{marginBottom: "32px"}}>
                            <h2 className="planning-title">Outcomes</h2>
                            <p className="planning-text">As a result, we provide client with:</p>
                            <ul className="white-dots-list">
                                <li>Specification Document</li>
                                <li>Wireframes</li>
                                <li>Clickable prototype</li>
                                <li>Project estimate</li>
                            </ul>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
        ;
};

export default ElmartPlanning;