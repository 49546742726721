import React, {useEffect} from 'react';

import UX_bg_pic from '../../assets/images/ux-bg-min.png'
import './styles.css'
import {Container} from "@mui/material";
import UxSteps from "../../components/UxSteps/UxSteps";
import UxService from "../../components/UxService/UxService";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import CaseStudySocial from "../../components/CaseStudySocial/CaseStudySocial";
import DetailedStack from "../../components/DetailedStack/DetailedStack";
import FAQs from "../../components/FAQs/FAQs";
import ContactUs from "../../components/ContactUs/ContactUs";
import UxReasons from "../../components/UxReasons/UxReasons";


const textArr = [
    {
        title: "User-Centered Design",
        text: "At Dartlab, users take center stage. Our designs are a product of in-depth research, ensuring every element resonates with your audience.",
        id: 0,
    },
    {
        title: "Responsive Design",
        text: "Seamless experiences across devices are non-negotiable. Our expertise guarantees designs that adapt flawlessly to desktops, tablets, and smartphones.",
        id: 1,
    },
    {
        title: "Innovative Solutions",
        text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
        id: 2,
    }
];



const Ux = () => {

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <div>
            <div className="ux-block"
                 style={{
                     backgroundImage: `url(${UX_bg_pic})`,
                     backgroundSize: 'cover',
                     backgroundColor: "#161616",
                     backgroundPosition: 'center',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <div className="overlay-ux">
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className={"ux-title-wrapper"}>
                            <h2 className="ux-main-title">Elevate user experience with our UX / UI design services</h2>
                        </div>
                    </Container>
                </div>
            </div>
            <div className="ux-white-box">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gradient-title-wrapper">
                        <h2 className="bigger-gradient">Crafting seamless experiences</h2>
                    </div>
                    <p className="black-text-web">At Dartlab, our UX / UI design philosophy revolves around creating not
                        just interfaces but
                        seamless and intuitive experiences that resonate with users. Our meticulous methodology is a
                        blend of art and science, ensuring that every design decision is backed by data and user
                        insights.</p>
                </Container>
            </div>
            <div className="black">
                <UxSteps/>
                <UxService/>
            </div>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div style={{padding: "62px 0 28px"}}>
                    <h2 className="ux-benefit-title">Benefits of design services with our team</h2>
                    <div className="change-block-wrapper">
                        <ChangeableTextBlock textArr={textArr} type={"ux"}/>
                    </div>
                </div>
            </Container>
            <div className="black-background">
                <CaseStudySocial/>
                <div className="why-us-box">
                    <h2 className="white-title">Why choose us</h2>
                    <p className="white-text">Choose Dartlab for a design partnership where expertise, experience, and
                        unwavering quality come
                        together for exceptional UX / UI solutions.</p>
                </div>
            </div>
            <UxReasons/>
            <FAQs/>
            <div style={{backgroundColor: "#161616", padding: "62px 0 0"}}>
                <ContactUs/>
            </div>
        </div>

    );
};

export default Ux;