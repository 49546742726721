import React from 'react';
import {Box, Container, Grid} from "@mui/material";

import './styles.css';

const FAQs = () => {
    return (
        <div className="white-bg-block" style={{paddingTop:"47px"}}>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h1 className="black-inner-title">
                    FAQs
                </h1>
                <p className="black-text" style={{textAlign: 'center'}}>Ask us anything. We're here to assist you.</p>

                <Grid container justifyContent="space-between" style={{marginTop:"50px"}}>
                    <Grid item xs={12} md={5.5}>
                        <div className="faq-text-box">
                            <p className="text-red faq-text-red">What details do you need to discuss my project and start work?</p>
                            <p className="text-black faq-text-black">
                                Simply submit your project idea to us via our contact form. We will contact you to clarify
                                further requirements and prepare the project time & cost estimates. For more details, you
                                can get in touch  <a href={"#"}>example@dartlab.io</a>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.3}>
                        <div className="faq-text-box">
                            <p className="text-red faq-text-red">What details do you need to discuss my project and start work?</p>
                            <p className="text-black faq-text-black">
                                Simply submit your project idea to us via our contact form. We will contact you to clarify
                                further requirements and prepare the project time & cost estimates. For more details, you
                                can get in touch  <a href={"#"}>example@dartlab.io</a>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                        <div className="faq-text-box">
                            <p className="text-red faq-text-red">What details do you need to discuss my project and start work?</p>
                            <p className="text-black faq-text-black">
                                Simply submit your project idea to us via our contact form. We will contact you to clarify
                                further requirements and prepare the project time & cost estimates. For more details, you
                                can get in touch  <a href={"#"}>example@dartlab.io</a>
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.3}>
                        <div className="faq-text-box">
                            <p className="text-red faq-text-red">What details do you need to discuss my project and start work?</p>
                            <p className="text-black faq-text-black">
                                Simply submit your project idea to us via our contact form. We will contact you to clarify
                                further requirements and prepare the project time & cost estimates. For more details, you
                                can get in touch  <a href={"#"}>example@dartlab.io</a>
                            </p>
                        </div>
                    </Grid>
                </Grid>

                <div className="faq-contact-box">
                    <Grid container justifyContent="space-between" alignItems={"center"}>
                        <Grid item xs={12} md={8}>
                            <p className="faq-contact-text-bold">
                                Still have questions?
                            </p>
                            <p className="faq-contact-text-normal">Can't find the answer you're looking for? Please chat to our friendly team.</p>
                        </Grid>
                        <Grid item>
                                <button className={'contact-us'}>Contact us</button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default FAQs;