import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css'

import {EffectCoverflow, Pagination, Navigation} from 'swiper';

import slide_image_1 from '../../assets/images/iPhone 14 Pro (2).png';
import slide_image_2 from '../../assets/images/iPhone 14 Pro (2).png';
import slide_image_3 from '../../assets/images/iPhone 14 Pro (2).png';
import slide_image_4 from '../../assets/images/iPhone 14 Pro (2).png';
import slide_image_5 from '../../assets/images/iPhone 14 Pro (2).png';

const SliderMobile = ({images}) => {
    return (
        <div className="container">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                autoplay={{delay: 4000}}
                spaceBetween={10}
                slidesPerView={1.8}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 10,
                    depth: 230,
                    modifier: 2.6,
                }}
                pagination={{el: '.swiper-pagination', clickable: true}}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"

                breakpoints={{
                    // when window width is >= 320px
                    500: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 275,
                            modifier: 2.4,
                        }
                    },
                    700: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 280,
                            modifier: 2.4,
                        }
                    },
                    // when window width is >= 480px
                    // 480: {
                    //     slidesPerView: 1.3,
                    //     spaceBetween: 10
                    // },

                }

                }
            >
                <SwiperSlide>
                    <img src={slide_image_1} alt="slide_image"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide_image_2} alt="slide_image"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide_image_3} alt="slide_image"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide_image_4} alt="slide_image"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide_image_5} alt="slide_image"/>
                </SwiperSlide>


                {/*<div className="slider-controler">*/}
                {/*    <div className="swiper-button-prev slider-arrow">*/}
                {/*        <ion-icon name="arrow-back-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*    <div className="swiper-button-next slider-arrow">*/}
                {/*        <ion-icon name="arrow-forward-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*    <div className="swiper-pagination"></div>*/}
                {/*</div>*/}
            </Swiper>
        </div>
    );
};

export default SliderMobile;