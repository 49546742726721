import React from 'react';
import { Container } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import lineImg from '../../assets/images/llline.svg'
import goal from '../../assets/images/goal.svg'
import './styles.css'

const Timeline = ({ arr, img, icon }) => {
    const max800 = useMediaQuery({ maxWidth: 760 });
    const max430 = useMediaQuery({ maxWidth: 430 });

    return (
        <div style={{backgroundColor:"#2A2A2A"}}>
            {max430 ? (
                <div className="timeline-box-small">
                    <Container maxWidth={false} sx={{ maxWidth: "1248px" }}>
                        <div className="timeline-small-screen">
                            <img src={lineImg} alt="gettik-timeline" className="timeline-img-small"/>
                            <img src={icon} alt="gettik-timeline" className="car-icon-small" />
                            <img src={goal} alt="gettik-timeline" className="goal-icon-small" />
                            {Array(5).fill().map((_, index) => (
                                <div className={`circle circle-small-${index + 1}`} key={index}></div>
                            ))}
                            {arr.length > 0 && arr.map(obj => (
                                <div className={`timeline-small-screen-box timeline-small-${obj.id}`} key={obj.id}>
                                    <h3 className="timeline-title-small">{obj.title}</h3>
                                    <p className="timeline-text-small">{obj.text}</p>
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
            ) : max800 ? (
                <div className="timeline-box-mobile">
                    <Container maxWidth={false} sx={{ maxWidth: "1248px" }}>
                        <div className="timeline-parent-mobile">
                            <img src={lineImg} alt="gettik-timeline" className="timeline-img" style={{ width: "95%" }} />
                            <img src={icon} alt="gettik-timeline" className="car-icon" />
                            <img src={goal} alt="gettik-timeline" className="goal-icon" />
                            {Array(7).fill().map((_, index) => (
                                <div className={`circle circle-${index + 1}`} key={index}></div>
                            ))}
                            {arr.length > 0 && arr.map(obj => (
                                <div className={`timeline-wrapper-mobile timeline-mobile-${obj.id}`} key={obj.id}>
                                    <h3 className="timeline-title">{obj.title}</h3>
                                    <p className="timeline-text">{obj.text}</p>
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
            ) : (
                <div className="timeline-box">
                    <Container maxWidth={false} sx={{ maxWidth: "1248px" }}>
                        <div className="timeline-parent">
                            <img src={img} alt="gettik-timeline" className="timeline-img" />
                            {arr.length > 0 && arr.map(obj => (
                                <div className={`timeline-wrapper timeline-${obj.id}`} key={obj.id}>
                                    <h3 className="timeline-title">{obj.title}</h3>
                                    <p className="timeline-text">{obj.text}</p>
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
            )}
        </div>
    );
};

export default Timeline;
