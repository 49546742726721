import React, {useEffect} from 'react';
import {Container, Grid} from "@mui/material";

import ElmartPlanning from "../../components/ElmartComponents/ElmartPlanning/ElmartPlanning";
import PlayMarket from "../../components/ElmartComponents/PlayMarket/PlayMarket";
import ElmartFeatures from "../../components/ElmartComponents/ElmartFeatures/ElmartFeatures";
import ElmartStack from "../../components/ElmartComponents/ElmartStack/ElmartStack";
import GettikReviews from "../../components/GettikCaseComponents/GettikReviews/GettikReviews";
import ContactUs from "../../components/ContactUs/ContactUs";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import Timeline from "../../components/Timeline/Timeline";

import ellipse from '../../assets/images/Ellipse-1.svg'
import ellipse2 from '../../assets/images/Ellipse-2.svg'
import avatar from '../../assets/images/business-avatar.jpeg'
import arrowRight from '../../assets/images/arrow-right-black.svg'
import elmartTimeline from "../../assets/images/elmart-timeline.svg";
import basketIcon from "../../assets/images/basket-icon.svg";
import {useMediaQuery} from "react-responsive";
import playMarket from "../../assets/images/play-market.svg";
import appStore from "../../assets/images/ios.svg";

import './styles.css';

const ElmartCase = () => {
    const max660 = useMediaQuery({maxWidth: 660})

    const timelineArr = [
        {id: 1, title: "Time", text: "6 month"},
        {id: 2, title: "Team", text: "5"},
        {id: 3, title: "Platform", text: "IOS, Android"},
        {id: 4, title: "Type", text: "Map"},
        {id: 5, title: "Industry", text: "Transportation"},
    ]

    const textArr = [
        {
            title: "Project idea",
            text: "Elmart revolutionizes the B2B wholesale market with free classified ads and seamless integration between classifieds and the marketplace. It offers tailored applications for sellers and buyers, providing a customized experience for each.",
            id: 0,
        },
        {
            title: "Target audience",
            text: "Seamless experiences across devices are non-negotiable. Our expertise guarantees designs that adapt flawlessly to desktops, tablets, and smartphones.",
            id: 1,
        },
        {
            title: "Project challenges",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 2,
        },
        {
            title: "Results",
            text: "Creativity fuels our problem-solving. Explore a portfolio where challenges become opportunities, delivering unique and memorable user experiences. Dartlab—where innovation meets design excellence.",
            id: 3,
        }
    ];

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <>
            <div className="elmart-case-main">
                <div className="elmart-case">
                    <img src={ellipse} alt="ellipse" className="ellipse1"/>
                    <img src={ellipse2} alt="ellipse" className="ellipse2"/>

                    {!max660 && (
                        <>
                            <div className="business">
                                <img src={avatar} alt="avatar"/>
                                <div className="business-wrapper">
                                    <p>Business</p>
                                </div>
                            </div>
                            <div className="business business-2">
                                <img src={avatar} alt="avatar"/>
                                <div className="business-wrapper">
                                    <p>Business</p>
                                </div>
                            </div>
                        </>
                    )}

                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <Grid container justifyContent="center">
                            <div className="elmart-case-inner">
                                <div>
                                    <h2 className="elmart-case-title">B2B Whole</h2><span>sale</span>
                                </div>
                                <div>
                                    <span>Market</span><h2 className="elmart-case-title">place</h2>
                                </div>
                                {!max660 ? (
                                    <div style={{maxWidth: "530px", margin: "0 auto"}}>
                                        <div className="gradient-arrow-wrapper">
                                            <img src={arrowRight} alt="arrow-rigth"/>
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{margin: "24px 0"}}>
                                    </div>
                                )}

                                <p>
                                    A web software solution and a cross-platform mobile application for a marketplace
                                    working in the CIS
                                </p>

                                <button className="estimate-btn">Estimate your project</button>

                                {max660 && (
                                    <div className="business-box-small">
                                        <div className="business-small">
                                            <img src={avatar} alt="avatar"/>
                                            <div className="business-wrapper-small">
                                                <p>Business</p>
                                            </div>
                                        </div>
                                        <div className="gradient-arrow-wrapper-small">
                                            <img src={arrowRight} alt="arrow-rigth"/>
                                        </div>
                                        <div className="business-small ">
                                            <img src={avatar} alt="avatar"/>
                                            <div className="business-wrapper-small">
                                                <p>Business</p>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Container>
                </div>
            </div>
            <Timeline arr={timelineArr} img={elmartTimeline} icon={basketIcon}/>
            <div style={{backgroundColor: "#F6F6F6"}}>
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    <div className="gettik-changeable-box">
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <h2 className="changeable-box-elmart-title">Elmart</h2>
                        </div>
                        <p className="gettik-changeable-box-text">B2B wholesale marketplace</p>
                        <div className="change-block-wrapper">
                            <ChangeableTextBlock textArr={textArr} type={"gettik-case"}/>
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <img src={playMarket} alt="play-market" className="play-market"/>
                            <img src={appStore} alt="app-market" className="app-market"/>
                        </div>
                    </div>
                </Container>
            </div>
            <ElmartPlanning/>
            <PlayMarket/>
            <ElmartFeatures/>
            <ElmartStack/>
            <GettikReviews/>
            <div style={{backgroundColor: "#161616", paddingTop:"62px"}}>
                <ContactUs/>
            </div>
        </>
    );
};

export default ElmartCase;