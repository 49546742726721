import React from 'react';
import {Container, Grid, useTheme, useMediaQuery} from "@mui/material";

import step1 from '../../assets/images/ux-1-step.svg';
import step2 from '../../assets/images/ux-2-step.svg';
import step3 from '../../assets/images/ux-3-step.svg';
import step4 from '../../assets/images/ux-4-step.svg';
import './styles.css'

const UxSteps = () => {
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{padding:"63px 0 43px"}}>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <Grid container justifyContent={isMdScreen ? 'space-evenly' : 'space-between'}>
                    <Grid item xs={4.7}  md={2.6} style={{marginBottom:"22px"}}>
                        <div className="step-box">
                            <div className="ux-step-img-wrapper">
                                <img src={step1} alt="step-1"/>
                            </div>
                            <h4 className="step-title">User research</h4>
                        </div>
                    </Grid>
                    <Grid item xs={4.7}  md={2.6} style={{marginBottom:"22px"}}>
                        <div className="step-box">
                            <div className="ux-step-img-wrapper">
                                <img src={step2} alt="step-2"/>
                            </div>
                            <h4 className="step-title">Wireframing</h4>
                        </div>
                    </Grid>
                    <Grid item xs={4.7}  md={2.6} style={{marginBottom:"22px"}}>
                        <div className="step-box">
                            <div className="ux-step-img-wrapper">
                                <img src={step3} alt="step-3"/>
                            </div>
                            <h4 className="step-title">Prototyping</h4>
                        </div>
                    </Grid>
                    <Grid item xs={4.7}  md={2.6} style={{marginBottom:"22px"}}>
                        <div className="step-box">
                            <div className="ux-step-img-wrapper">
                                <img src={step4} alt="step-4"/>
                            </div>
                            <h4 className="step-title">Iterative testing</h4>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    );
};

export default UxSteps;