import React from 'react';
import {Container, Grid} from "@mui/material";

import Chain from '../../assets/images/chain.png';
import Basket from '../../assets/images/basket.png';
import User from '../../assets/images/user.png';
import Cloud from '../../assets/images/cloud.png';

import './styles.css';
import Lightning from "../../assets/images/lightning.png";

const WebServices = () => {
    return (
        <div className="web-service-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className="white-title" style={{marginBottom: "10px"}}>Web development services</h2>
                <p className="white-text" style={{maxWidth: "982px", margin: "0 auto", textAlign: "center"}}>We offer a
                    comprehensive range of web development services to meet your specific needs.</p>

                <div className="web-service-inner">
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={5.69} style={{marginBottom: "62px"}}>
                            <div className="flutter-dark">
                                <div className="gradient-title-wrapper">
                                    <h3 className="biggest-gradient margin-bottom-42">Custom Web Applications</h3>
                                </div>
                                <p className="white-text" style={{marginBottom: "26px"}}>
                                    We specialize in creating custom web applications tailored to your unique
                                    requirements.
                                </p>

                                <div className="web-service-img-wrapper" style={{
                                    backgroundImage: `url(${Chain})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.69} style={{marginBottom: "62px"}}>
                            <div className="flutter-white">
                                <h3 className="biggest-title margin-bottom-42" style={{color: "#161616"}}>Shopify
                                    Solutions</h3>
                                <p className="black-text-web" style={{paddingLeft: "0", marginBottom: "26px"}}>
                                    Our team can design and develop secure and user-friendly e-commerce platforms for
                                    your
                                    online business.
                                </p>
                                <div className="web-service-img-wrapper" style={{
                                    backgroundImage: `url(${Basket})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.69} style={{marginBottom: "62px"}}>
                            <div className="flutter-white">
                                <h3 className="biggest-title margin-bottom-42" style={{color: "#161616"}}>CMS (Admin
                                    Panel)</h3>
                                <p className=" black-text-web" style={{paddingLeft: "0", marginBottom: "26px"}}>
                                    We build intuitive content management systems to help you manage your website
                                    easily.
                                </p>
                                <div className="web-service-img-wrapper" style={{
                                    backgroundImage: `url(${User})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.69} style={{marginBottom: "62px"}}>
                            <div className="flutter-dark">
                                <div className="gradient-title-wrapper">
                                    <h3 className="biggest-gradient margin-bottom-42">Third Party Integration</h3>
                                </div>
                                <p className="white-text" style={{marginBottom: "26px"}}>
                                    We can seamlessly integrate third-party APIs, plugins, and services to enhance your
                                    website's functionality.
                                </p>
                                <div className="web-service-img-wrapper" style={{
                                    backgroundImage: `url(${Cloud})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
};

export default WebServices;