import React from 'react';
import {Container, Grid} from "@mui/material";

import picture1 from '../../assets/images/company-pic1-min.jpeg'
import picture2 from '../../assets/images/company-pic2-min.jpeg'
import './styles.css';
import textImg from "../../assets/images/dartlab-text.svg";

const CompanyCulture = () => {
    return (
        <div className="company-culture">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={5.70} sx={{marginBottom:"62px"}}>
                        <div className="culture-text-box">
                            <h2 className="white-title">
                                Culture and values
                            </h2>
                            <p className="white-text">
                                At Dartlab, our culture is defined by a set of core values that guide our actions and
                                decisions. These values include a relentless pursuit of quality, a dedication to
                                innovation, a client-centric approach, and a strong commitment to integrity and ethical
                                business practices. Our team embodies these values in every project we undertake.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5.70} sx={{marginBottom:"62px"}}>
                        <div className="culture-pic-wrapper"
                             style={{
                                 backgroundImage: `url(${picture1})`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5.70} sx={{marginBottom:"62px"}}>
                        <div className="culture-pic-wrapper"
                             style={{
                                 backgroundImage: `url(${picture2})`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >

                        </div>
                    </Grid>
                    <Grid item xs={12}  sm={12} md={5.70}  sx={{marginBottom:"62px"}}>
                        <div className="culture-approach-box">
                            <div style={{display:"flex", justifyContent:"start"}}>
                                <h2 className="gradient" style={{marginBottom:"10px"}}>
                                    Our approach
                                </h2>
                            </div>
                            <p className="dark-text" style={{paddingRight:"10px"}}>
                                Dartlab's approach to IT solutions is characterized by our dedication to delivering
                                exceptional work, our innovative methodologies, and our commitment to crafting tailored
                                solutions to address the unique needs of our clients. We prioritize open communication,
                                collaboration, and a strong focus on meeting project goals and deadlines.
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default CompanyCulture;