import React, {useState} from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import CycleTab from "../CycleTab/CycleTab";

import {ReactComponent as DiscoveryIcon} from '../../assets/images/discovery.svg';
import {ReactComponent as DesignIcon} from '../../assets/images/design.svg';
import {ReactComponent as DevelopmentIcon} from '../../assets/images/development.svg';
import {ReactComponent as DeploymentIcon} from '../../assets/images/deployment.svg';
import {ReactComponent as MaintenanceIcon} from '../../assets/images/maintenance.svg';
import {ReactComponent as DesignIconGradient} from '../../assets/images/gradient-design.svg';
import {ReactComponent as DevelopmentIconGradient} from '../../assets/images/gradient-development.svg';
import {ReactComponent as DeploymentIconGradient} from '../../assets/images/gradient-deployment.svg';
import {ReactComponent as MaintenanceIconGradient} from '../../assets/images/gradient-maintanance.svg';
import './styles.css';


const discovery = {
    title1: 'Discovery',
    text1: 'This phase is designed to finalise requirements, identify pitfalls, and craft essential assets for a development team that’ll help you cut costs. It requires close cooperation with you for approving wireframes, a user journey, and reviewing functionality in the specification',
    title2: 'Staff',
    staff: ['Business Analyst', 'Project Manager', 'Solution Architect', 'UI/UX designer', 'QA engineer']
}

const design = {
    title1: 'Design',
    text1: 'Intuitive and captivating product design is halfway to success. Having immersed into your product strategy, we create several design concepts with business goals in mind. After you pick one, we start working on the prototype based on the wireframes',
    title2: 'Staff',
    staff: ['Project Manager', 'UI/UX designer']
};

const development = {
    title1: 'Development',
    text1: 'We follow the agile software development methodology to ensure transparency, quality, and flexibility to changes. Having chosen the optimal tech stack for your product, we’ll design, develop, and test your product adhering to the latest techniques and technologies',
    title2: 'Staff',
    staff: ['Project Manager', 'Software Engineer', 'QA engineer', 'DevOps']
};

const deployment = {
    title1: 'Deployment',
    text1: 'With years of experience releasing digital products, we’ll do all necessary final preparations to get your product up and running. Be it a submission process, server deployment, or cloud configuration — we’ll handle it quickly following the latest practices',
    title2: 'Staff',
    staff: ['Project Manager', 'Software Engineer', 'QA engineer', 'DevOps', 'UI/UX designer']
};

const maintenance = {
    title1: 'Maintenance',
    text1: 'MVP is not the final destination. We’ll help you roll out product updates and new features according to your tests, assumptions, and KPIs. Our main goal is to help you grab the biggest market share possible',
    title2: 'Staff',
    staff: ['Project Manager', 'Software Engineer', 'QA engineer', 'DevOps', 'UI/UX designer']
};

const stages = [
    {
        key: 'discovery',
        title: 'Discovery',
        icon: <DiscoveryIcon className="life-cycle-icons"/>,
        activeIcon: <DiscoveryIcon className="life-cycle-icons"/>,
        details: discovery
    },
    {
        key: 'design',
        title: 'Design',
        icon: <DesignIcon className="life-cycle-icons"/>,
        activeIcon: <DesignIconGradient className="life-cycle-icons"/>,
        details: design
    },
    {
        key: 'development',
        title: 'Development',
        icon: <DevelopmentIcon className="life-cycle-icons"/>,
        activeIcon: <DevelopmentIconGradient className="life-cycle-icons"/>,
        details: development
    },
    {
        key: 'deployment',
        title: 'Deployment',
        icon: <DeploymentIcon className="life-cycle-icons"/>,
        activeIcon: <DeploymentIconGradient className="life-cycle-icons"/>,
        details: deployment
    },
    {
        key: 'maintenance',
        title: 'Maintenance',
        icon: <MaintenanceIcon className="life-cycle-icons"/>,
        activeIcon: <MaintenanceIconGradient className="life-cycle-icons"/>,
        details: maintenance
    },
];


const LifeCycle = () => {
    const [activeStage, setActiveStage] = useState('discovery');
    const max900 = useMediaQuery({ maxWidth: 900});

    const updateActiveButtons = (stageKey) => {
        const stageIndex = stages.findIndex(stage => stage.key === stageKey);
        const newActiveButtons = stages.reduce((acc, stage, index) => {
            acc[stage.key] = index <= stageIndex;
            return acc;
        }, {});
        return newActiveButtons;
    };

    const [activeButtons, setActiveButtons] = useState(updateActiveButtons('discovery'));

    const handleStageClick = (stageKey) => {
        setActiveStage(stageKey);
        setActiveButtons(updateActiveButtons(stageKey));
    };

    const activeIndex = stages.findIndex(stage => stage.key === activeStage);
    const progressWidth = `${(activeIndex + 1) * (100 / stages.length)}%`;

    return (
        <div className="life-cycle-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="life-cycle-title-wrapper">
                    <h2 className='life-cycle-title'>We follow the Software Development Life Cycle (SDLC)</h2>
                    <p>Preparation and project planning are important to produce software with the highest quality and
                        lowest cost in the shortest time possible as well as the other phases of SDLC methodology</p>
                </div>

                {max900 ? (
                    <div>
                        <div className="scroll-progress-bar">
                            {stages.map((stage) => (
                                <div className="scroll-progress-item" key={stage.key} onClick={() => handleStageClick(stage.key)}>
                                    <div className={`life-cycle-box ${activeButtons[stage.key] ? 'active-cycle' : ''}`}>
                                        {activeButtons[stage.key] ? stage.activeIcon : stage.icon}
                                        <p className={`life-cycle-text ${activeButtons[stage.key] ? 'active-cycle-text' : ''}`}>{stage.title}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="cycle-progress-box-scroll">
                            <div className="progress-gradient" style={{width: progressWidth}}></div>
                        </div>
                    </div>

                ):(
                    <div>
                        <Grid container justifyContent="space-evenly">
                            {stages.map((stage) => (
                                <Grid item xs={2.2} key={stage.key} onClick={() => handleStageClick(stage.key)}>
                                    <div className={`life-cycle-box ${activeButtons[stage.key] ? 'active-cycle' : ''}`}>
                                        {activeButtons[stage.key] ? stage.activeIcon : stage.icon}
                                        <p className={`life-cycle-text ${activeButtons[stage.key] ? 'active-cycle-text' : ''}`}>{stage.title}</p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>

                        <div className="cycle-progress-box">
                            <div className="progress-gradient" style={{width: progressWidth}}></div>
                        </div>
                    </div>
                )}

                <div className="cycle-progress">
                    {!max900 && (
                        <div className="triangle-up" style={{left: `${(activeIndex * 20) + 5}%`}}></div>
                    )}
                    <CycleTab obj={stages[activeIndex].details}/>
                    <div className="book-btn-wrapper">
                        <button className="styled-button" style={{maxWidth:"286px", width:"100%"}}>Book a consultation</button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default LifeCycle;