import React, {useState} from 'react';
import {Container} from "@mui/material";
import tick from '../../assets/images/tick.svg'
import python from '../../assets/images/python.svg';
import node from '../../assets/images/node.svg';
import php from '../../assets/images/php.svg';
import dart from '../../assets/images/dart.svg';
import digital from '../../assets/images/digitalOcean.svg';
import docker from '../../assets/images/docker.svg';
import aws from '../../assets/images/aws.svg';
import elastic from '../../assets/images/elastic.svg';
import meili from '../../assets/images/meili.svg';
import nginx from '../../assets/images/Nginx.svg';
import apache from '../../assets/images/apache.svg';
import mySQL from '../../assets/images/mySql.svg';
import sqlite from '../../assets/images/sqlite.svg';
import postgre from '../../assets/images/postgre.svg';
import js from '../../assets/images/js.svg';
import html from '../../assets/images/html.svg';
import css from '../../assets/images/css.svg';
import react from '../../assets/images/react.svg';
import flutter from '../../assets/images/flutter.svg';
import kotlin from '../../assets/images/kotlin.svg';
import swift from '../../assets/images/swift.svg';
import figma from '../../assets/images/figma.svg';
import adobe from '../../assets/images/adobe.svg';
import {useMediaQuery} from "react-responsive";
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation, Pagination} from "swiper";
import screen1 from "../../assets/images/screen1.png";
import screen2 from "../../assets/images/screen2.png";
import screen3 from "../../assets/images/screen3.png";
import screen4 from "../../assets/images/screen4.png";
import screen5 from "../../assets/images/screen5.png";


const TechStack = () => {
    const [selected, setSelected] = useState('1')
    const myArray = Array.from({ length: 13 }, (_, index) => index + 1);
    const mobile = useMediaQuery({maxWidth:600})

    return (
        <div className='tech-stack'>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="gradient-title-wrapper">
                    <h2 className='gradient' style={{margin: "0 0 38px"}}>Tech Stack</h2>
                </div>
                {mobile ? (
                    <div className="stack-slider">
                        <Swiper
                            grabCursor={true}
                            loop={true}
                            spaceBetween={10}
                            slidesPerView={2.4}
                            className="tech-swiper_container"
                            breakpoints={{
                                    // when window width is >= 320px
                                    420: {
                                        slidesPerView: 2.9,
                                        spaceBetween: 10
                                    },
                                    // when window width is >= 480px
                                    // when window width is >= 740px
                                    540: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 0
                                    },


                                }}
                        >
                            <SwiperSlide>
                                <button id={'1'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '1' ? "inline-block" : "none"}}/>
                                    Backend
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button id={'2'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '2' ? "inline-block" : "none"}}/>
                                    Frontend
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button id={'3'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '3' ? "inline-block" : "none"}}/>
                                    Database
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button id={'4'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '4' ? "inline-block" : "none"}}/>
                                    Mobile
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button id={'5'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '5' ? "inline-block" : "none"}}/>
                                    UX / UI
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button id={'6'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                    <img src={tick} alt="selected" style={{display: selected === '6' ? "inline-block" : "none"}}/>
                                    Infrastructure
                                </button>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                ):(
                    <div className="tech-tabs">
                        <button id={'1'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '1' ? "inline-block" : "none"}}/>
                            Backend
                        </button>
                        <button id={'2'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '2' ? "inline-block" : "none"}}/>
                            Frontend
                        </button>
                        <button id={'3'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '3' ? "inline-block" : "none"}}/>
                            Database
                        </button>
                        <button id={'4'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '4' ? "inline-block" : "none"}}/>
                            Mobile
                        </button>
                        <button id={'5'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '5' ? "inline-block" : "none"}}/>
                            UX / UI
                        </button>
                        <button id={'6'} onClick={(e) => setSelected(e.target.id)}>
                            <img src={tick} alt="selected" style={{display: selected === '6' ? "inline-block" : "none"}}/>
                            Infrastructure
                        </button>
                    </div>
                )}
                <div className='stack-parent'>
                    <div className={ selected === '1' ? 'stack-child active':'stack-child'}>
                        <img src={python} alt="python"/>
                        <p>Python</p>
                    </div>
                    <div className={ selected === '1' ? 'stack-child active':'stack-child'}>
                        <img src={node} alt="node"/>
                        <p>Node.js</p>
                    </div>
                    <div className={ selected === '1' || selected === '2' ? 'stack-child active':'stack-child'}>
                        <img src={php} alt="php"/>
                        <p>Php</p>
                    </div>
                    <div className={ selected === '1' || selected === '2' ? 'stack-child active':'stack-child'}>
                        <img src={dart} alt="dart"/>
                        <p>Dart</p>
                    </div>
                    <div className={ selected === '6'  ? 'stack-child active':'stack-child'}>
                        <img src={digital} alt="digital"/>
                        <p>DigitalOcean</p>
                    </div>
                    <div className={ selected === '6' ? 'stack-child active':'stack-child'}>
                        <img src={docker} alt="docker"/>
                        <p>Docker</p>
                    </div>
                    <div className={ selected === '6' ? 'stack-child active':'stack-child'}>
                        <img src={aws} alt="aws"/>
                        <p>AWS</p>
                    </div>
                    <div className={ selected === '6'  ? 'stack-child active':'stack-child'}>
                        <img src={elastic} alt="elastic"/>
                        <p>ElasticSearch</p>
                    </div>
                    <div className={ selected === '6'  ? 'stack-child active':'stack-child'}>
                        <img src={meili} alt="meili"/>
                        <p>MeiliSearch</p>
                    </div>
                    <div className={ selected === '6'  ? 'stack-child active':'stack-child'}>
                        <img src={nginx} alt="nginx"/>
                        <p>Nginx</p>
                    </div>
                    <div className={ selected === '6'  ? 'stack-child active':'stack-child'}>
                        <img src={apache} alt="apache"/>
                        <p>Apache</p>
                    </div>
                    <div className={ selected === '3'  ? 'stack-child active':'stack-child'}>
                        <img src={mySQL} alt="mySQL"/>
                        <p>MySQL</p>
                    </div>


                    {/*second row*/}
                    <div className={ selected === '3'  ? 'stack-child active':'stack-child'}>
                        <img src={sqlite} alt="sqlite"/>
                        <p>SQLite</p>
                    </div>
                    <div className={ selected === '3'  ? 'stack-child active':'stack-child'}>
                        <img src={postgre} alt="postgre"/>
                        <p>PostgreSQL</p>
                    </div>
                    <div className={ selected === '1' || selected === '2' ? 'stack-child active':'stack-child'}>
                        <img src={js} alt="js"/>
                        <p>JavaScript</p>
                    </div>
                    <div className={ selected === '2'  ? 'stack-child active':'stack-child'}>
                        <img src={html} alt="html"/>
                        <p>HTML</p>
                    </div>
                    <div className={ selected === '2'  ? 'stack-child active':'stack-child'}>
                        <img src={css} alt="css"/>
                        <p>CSS</p>
                    </div>
                    <div className={ selected === '2'  ? 'stack-child active':'stack-child'}>
                        <img src={react} alt="react"/>
                        <p>React</p>
                    </div>
                    <div className={ selected === '2' || selected === '4'  ? 'stack-child active':'stack-child'}>
                        <img src={flutter} alt="flutter"/>
                        <p>Flutter</p>
                    </div>
                    <div className={ selected === '2' || selected === '4'  ? 'stack-child active':'stack-child'}>
                        <img src={kotlin} alt="kotlin"/>
                        <p>Kotlin</p>
                    </div>
                    <div className={ selected === '2' || selected === '4'  ? 'stack-child active':'stack-child'}>
                        <img src={swift} alt="swift"/>
                        <p>Swift</p>
                    </div>
                    <div className={ selected === '5'  ? 'stack-child active':'stack-child'}>
                        <img src={figma} alt="figma"/>
                        <p>Figma</p>
                    </div>
                    <div className={ selected === '5'  ? 'stack-child active':'stack-child'}>
                        <img src={adobe} alt="adobe"/>
                        <p>Adobe</p>
                    </div>

                    {/*third row*/}
                    {myArray.map((i)=>(
                        <div className='stack-child'>

                        </div>
                    ))}

                </div>

            </Container>
        </div>
    );
};

export default TechStack;