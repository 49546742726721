import React from 'react';

import './styles.css'
import {Container} from "@mui/material";
import python from "../../assets/images/python.svg";
import node from "../../assets/images/node.svg";
import php from "../../assets/images/php.svg";
import dart from "../../assets/images/dart.svg";
import digital from "../../assets/images/digitalOcean.svg";
import docker from "../../assets/images/docker.svg";
import aws from "../../assets/images/aws.svg";
import elastic from "../../assets/images/elastic.svg";
import meili from "../../assets/images/meili.svg";
import nginx from "../../assets/images/Nginx.svg";
import apache from "../../assets/images/apache.svg";
import mySQL from "../../assets/images/mySql.svg";
import sqlite from "../../assets/images/sqlite.svg";
import postgre from "../../assets/images/postgre.svg";
import js from "../../assets/images/js.svg";
import html from "../../assets/images/html.svg";
import css from "../../assets/images/css.svg";
import react from "../../assets/images/react.svg";
import flutter from "../../assets/images/flutter.svg";
import kotlin from "../../assets/images/kotlin.svg";
import swift from "../../assets/images/swift.svg";
import figma from "../../assets/images/figma.svg";
import adobe from "../../assets/images/adobe.svg";
import {useMediaQuery} from "react-responsive";

const DetailedStack = () => {
    const myArray = Array.from({ length: 12 }, (_, index) => index + 1);
    const mobile = useMediaQuery({maxWidth:600})
    return (
        <div>
            <div className='tech-stack detailed-tech-stack'>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gradient-title-wrapper">
                        <h2 className='gradient' style={{margin: "30px 0 2px"}}>Tech Stack</h2>
                    </div>

                    <div className='stack-parent'>
                        <div className={'stack-child'}>
                            <img src={python} alt="python"/>
                            <p>Python</p>
                        </div>
                        <div className={'stack-child'}>
                            <img src={node} alt="node"/>
                            <p>Node.js</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={php} alt="php"/>
                            <p>Php</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={dart} alt="dart"/>
                            <p>Dart</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={digital} alt="digital"/>
                            <p>DigitalOcean</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={docker} alt="docker"/>
                            <p>Docker</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={aws} alt="aws"/>
                            <p>AWS</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={elastic} alt="elastic"/>
                            <p>ElasticSearch</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={meili} alt="meili"/>
                            <p>MeiliSearch</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={nginx} alt="nginx"/>
                            <p>Nginx</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={apache} alt="apache"/>
                            <p>Apache</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={mySQL} alt="mySQL"/>
                            <p>MySQL</p>
                        </div>


                        {/*second row*/}
                        <div className={'stack-child'}>
                        <img src={sqlite} alt="sqlite"/>
                            <p>SQLite</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={postgre} alt="postgre"/>
                            <p>PostgreSQL</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={js} alt="js"/>
                            <p>JavaScript</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={html} alt="html"/>
                            <p>HTML</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={css} alt="css"/>
                            <p>CSS</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={react} alt="react"/>
                            <p>React</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={flutter} alt="flutter"/>
                            <p>Flutter</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={kotlin} alt="kotlin"/>
                            <p>Kotlin</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={swift} alt="swift"/>
                            <p>Swift</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={figma} alt="figma"/>
                            <p>Figma</p>
                        </div>
                        <div className={'stack-child'}>
                        <img src={adobe} alt="adobe"/>
                            <p>Adobe</p>
                        </div>
                        <div className={'stack-child'}>
                        </div>

                    </div>

                </Container>
            </div>
        </div>
    );
};

export default DetailedStack;