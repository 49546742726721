import React from 'react';
import {Container, Grid} from "@mui/material";
import arrow from '../../assets/images/arrow-right.svg'

const Offer = () => {
    return (
        <div className={'offer'}>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className='block-title'>Special offers</h2>
            </Container>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={12} md={5.94} sx={{marginBottom:"20px"}}>
                        <div className='offer-first-box'>
                            <h3>For startups</h3>
                            <p>Get an experienced team to enhance your idea</p>
                            <button className='read-more-btn'>Read more <img src={arrow} alt="arrow"/></button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.94} sx={{marginBottom:"20px"}}>
                        <div className='offer-second-box'>
                            <div className={'dark-bg'}>
                                <h3>Team augmentation</h3>
                                <p>Get an experienced team to enhance your idea</p>
                                <button className="read-more-btn">Read more <img src={arrow} alt="arrow"/></button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
        </div>
    );
};

export default Offer;