import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppBar, Box, Container, Drawer, IconButton, ListItem, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {Link, useLocation, useNavigate} from "react-router-dom";

import logo from '../../assets/images/logo.svg'
import MenuIcon from '@mui/icons-material/Menu';
import './styles.css'


const AppToolBar = () => {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [toolbarColor, setToolbarColor] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showMobileDrawer, setShowMobileDrawer] = useState(false);
    const [showMobileDropdown, setShowMobileDropdown] = useState(false);


    const pages = [
        {name: 'Company', route: "/company"},
        {name: 'Services', route: "dropdown"},
        {name: 'For startups', route: "/startups"},
        {name: 'Team augmentation', route: "/team"},
    ];


    const changeColor = () => {
        if (pathname === "/") {
            if (window.scrollY > 100) {
                setToolbarColor(true)
            } else {
                setToolbarColor(false)
            }
        } else {
            setToolbarColor(true)
        }
    }


    if (pathname === "/") {
        window.addEventListener("scroll", changeColor);
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    // };
    //
    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };


    const handleOpenNavMenu = () => {
        setShowMobileDrawer(true);
    };

    const handleCloseNavMenu = () => {
        setShowMobileDrawer(false);
    };

    useEffect(() => {
        const handleClickOutsideDropdown = (event) => {
            const dropdownContainer = document.getElementById('dropdown-container');

            if (
                showDropdown &&
                dropdownContainer &&
                !dropdownContainer.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutsideDropdown);

        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, [showDropdown]);


    return (
        <>
            <AppBar position="fixed" elevation={0}
                    style={{
                        backgroundColor: pathname !== '/' ? "#161616" : (toolbarColor || showDropdown ? "#161616" : "transparent"),
                        color: "white",
                        transition: showDropdown ? "none" : "background-color 0.7s ease",
                    }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Toolbar disableGutters={true} sx={{marginTop: "10px", paddingBottom: "10px",}}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                display: {xs: 'none', md: "flex"},
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                height: "31px",
                                // margin: "23px 0"
                            }}
                        >

                            <img src={logo} alt="logo" style={{display: "block", height: "100%"}}/>

                        </Typography>

                        <Box sx={{
                            flexGrow: 1,
                            display: {xs: 'flex', md: "none"},
                            position: "relative",
                            zIndex: "77"
                        }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon sx={{color: "white", fontSize: "35px"}}/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                inputProps={{MenuProps: {disableScrollLock: true}}}
                                disableScrollLock={true}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                    width: "100%",
                                }}
                            >
                                <MenuItem key={1} onClick={handleCloseNavMenu}>
                                    <Typography
                                        textAlign="center"
                                        inputProps={{MenuProps: {disableScrollLock: true}}}
                                        onClick={() => navigate("/")}
                                    >
                                        Home
                                    </Typography>
                                </MenuItem>

                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        <Typography
                                            textAlign="center"
                                            inputProps={{MenuProps: {disableScrollLock: true}}}
                                            onClick={() => {
                                                if (page.route === "dropdown") {
                                                    setShowDropdown(!showDropdown)
                                                } else {
                                                    navigate(page.route)
                                                }
                                            }}
                                        >
                                            {page.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>

                        </Box>

                        <Box sx={{
                            display: {xs: 'none', md: "flex"},
                            justifyContent: "space-evenly",
                            flexGrow: "1",
                            margin: {md: "0 20px", lg: "0 50px"}

                        }}>

                            {pages.map(page => {
                                if (page.route === "dropdown") {
                                    return (
                                        <button
                                            key={page.name}
                                            className={(pathname === '/mobile' || pathname === "/web" || pathname === "ux") && pathname !== '/' ? "navigation-btn navigation-btn_active" : "navigation-btn"}
                                            onClick={() => setShowDropdown(!showDropdown)}
                                        >
                                            {page.name}
                                        </button>
                                    )
                                }
                                return (
                                    <button
                                        key={page.name}
                                        className={pathname === page.route && pathname !== '/' ? "navigation-btn navigation-btn_active" : "navigation-btn"}
                                        onClick={() => {
                                            navigate(page.route)
                                            setShowDropdown(false)
                                        }}
                                    >
                                        {page.name}
                                    </button>
                                )

                            })}

                        </Box>


                        <Box>
                            <button className={'contact-us'}>Contact us</button>
                        </Box>

                    </Toolbar>
                </Container>

                {showDropdown && (
                    <div id="dropdown-container" style={{padding: "8px 0 18px", backgroundColor: "#161616"}}>
                        <Container maxWidth={false} sx={{maxWidth: "1284px",}}>
                            <p className="dropdown-title">Services</p>
                            <Link to={"/mobile"} className="dropdown-link" onClick={() => setShowDropdown(false)}>Mobile
                                Development</Link>
                            <Link to={"/web"} className="dropdown-link" onClick={() => setShowDropdown(false)}>Web
                                Development</Link>
                            <Link to={"/ux"} className="dropdown-link" onClick={() => setShowDropdown(false)}>UX/UI
                                Design</Link>
                        </Container>
                    </div>
                )}
            </AppBar>

            <Drawer anchor="left" open={showMobileDrawer} onClose={() => {
                handleCloseNavMenu()
                setShowMobileDropdown(false)
            }}
            >
                <div style={{width: "250px", backgroundColor: "#161616", height: "100%", padding: "20px 10px 0"}}>
                    <img src={logo} alt="logo" style={{
                        width: "100%",
                        height: "auto",
                        opacity: "0.3",
                        marginBottom: "20px",
                        cursor: "pointer"
                    }} onClick={() => {
                        navigate('/')
                        handleCloseNavMenu()
                        setShowMobileDropdown(false);
                    }}/>

                    <ListItem key="home" onClick={() => {
                        handleCloseNavMenu()
                        setShowMobileDropdown(false)
                    }}>
                        <Link to="/" className={"dropdown-link"}>Home</Link>
                    </ListItem>

                    <ListItem onClick={() => {
                        handleCloseNavMenu()
                        setShowMobileDropdown(false)
                    }}>
                        <Link to="/company" className={"dropdown-link"}>Company</Link>
                    </ListItem>

                    <ListItem onClick={() => {
                        setShowMobileDropdown(!showMobileDropdown)
                    }}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Link to="#" className={"dropdown-link"}>Services</Link>

                            {showMobileDropdown && (
                                <div style={{margin: "10px 0 10px 24px"}}>
                                    <Link to={"/mobile"}
                                          className="dropdown-link dropdown-mobile"
                                          onClick={() => {
                                              handleCloseNavMenu()
                                              setShowMobileDropdown(false)
                                          }}>
                                        Mobile Development
                                    </Link>
                                    <Link to={"/web"}
                                          className="dropdown-link dropdown-mobile"
                                          onClick={() => {
                                              handleCloseNavMenu()
                                              setShowMobileDropdown(false)
                                          }}>
                                        Web Development
                                    </Link>
                                    <Link to={"/ux"}
                                          className="dropdown-link dropdown-mobile"
                                          onClick={() => {
                                              handleCloseNavMenu()
                                              setShowMobileDropdown(false)
                                          }}>
                                        UX/UI Design
                                    </Link>
                                </div>
                            )}
                        </div>
                    </ListItem>

                    <ListItem onClick={() => {
                        handleCloseNavMenu()
                        setShowMobileDropdown(false)
                    }}>
                        <Link to="/startups" className={"dropdown-link"}>For startups</Link>
                    </ListItem>

                    <ListItem onClick={() => {
                        handleCloseNavMenu()
                    }}>
                        <Link to="/team" className={"dropdown-link"}>Team augmentation</Link>
                    </ListItem>
                </div>
            </Drawer>
        </>
    );
};
export default AppToolBar;