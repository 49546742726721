import React, {useEffect} from 'react'
import {Container} from "@mui/material";

import WebServices from "../../components/WebServices/WebServices";

import webBg from '../../assets/images/purple-web-bg-min.png';
import './styles.css'
import DetailedStack from "../../components/DetailedStack/DetailedStack";
import FAQs from "../../components/FAQs/FAQs";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactUs from "../../components/ContactUs/ContactUs";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";


const textArr = [
    {
        title: "Expertise in Web Development",
        text: "Our team comprises seasoned professionals with extensive experience in a wide range of web technologies and methodologies.",
        id: 0
    },
    {
        title: "Custom Solutions",
        text: "We excel in developing custom web applications, content management systems,\n" +
            "    e-commerce platforms, and more.",
        id: 1
    },
    {
        title: "Responsive Design",
        text: "Our web solutions are responsive, ensuring an optimal user experience across various devices and screen sizes.",
        id: 2
    },
    {
        title: "Scalability",
        text: "We design websites and web applications with scalability in mind, enabling them to grow as your business expands.",
        id: 3
    },
    {
        title: "Quality Assurance",
        text: "Our commitment to quality means that your web projects are rigorously tested and optimized for performance.",
        id: 4
    },
]



const WebDev = () => {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <div style={{backgroundColor: "#161616", paddingTop: "85px"}}>
                <div className="web-dev-wrapper"
                     style={{
                         backgroundImage: `url(${webBg})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         backgroundRepeat: "no-repeat",
                         borderRadius: "10px",
                         width: "100%",
                         zIndex: -1
                     }}
                >
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="web-dev-main-block">
                            <h2 className="white-title">Web Development</h2>
                            <p className="white-text">We are dedicated to creating dynamic and responsive web solutions
                                that
                                cater to your unique needs. Our experienced team is committed to delivering high-quality
                                web
                                applications, websites, and digital experiences that align with your business goals.</p>
                        </div>
                    </Container>
                </div>
            </div>
            <div className="white-bg-block">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="gradient-title-wrapper">
                    <h2 className="bigger-gradient" style={{margin: "1px 0 62px"}}>Why choose Dartlab for Web
                        development</h2>
                </div>
                   <ChangeableTextBlock textArr={textArr}/>
                </Container>
            </div>
            <WebServices/>
            <DetailedStack/>
            <FAQs/>
            <div className="company-testimonials testimonials" style={{marginBottom: "0", paddingTop: "60px"}}>
                <h3 className="white-title" style={{marginTop: "-10px", marginBottom: "46px"}}>Testimonials</h3>
                <Testimonials/>
            </div>
            <div style={{backgroundColor: "#161616"}}>
                <ContactUs/>
            </div>
        </>

    );
};

export default WebDev;