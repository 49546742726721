import React from 'react';
import {useMediaQuery} from "react-responsive";
import {Container, Grid} from "@mui/material";

import feature3 from "../../../assets/images/sporthorse-feature-3.png";
import featureScreen1 from "../../../assets/images/sporthorse-feature-screen-1.svg";
import './styles.css'

const DarkFeature2 = () => {
    const max730 = useMediaQuery({maxWidth: 730});
    return (
        <div className="dark-features">
            <Container maxWidth={false} sx={{maxWidth: "1684px"}} disableGutters={true}>
                {max730 ? (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <Container maxWidth={false} sx={{maxWidth: "624px"}}>

                                    <div className="feature-dark-small">
                                        <h2 className="feature-white-title">E-commerce</h2>
                                        <p className="feature-white-text">
                                            The platform incorporates a comprehensive e-commerce system, enabling users
                                            to browse, purchase, and sell equestrian products and services. This system
                                            is designed to be intuitive and user-friendly, ensuring a seamless shopping
                                            experience.</p>
                                    </div>
                                </Container>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${feature3})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "660px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen"
                                     className="feature-dark-screen-img"/>

                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <div className="feature-dark-2">
                                    <h2 className="feature-white-title">E-commerce</h2>
                                    <p className="feature-white-text">
                                        The platform incorporates a comprehensive e-commerce system, enabling users to
                                        browse, purchase, and sell equestrian products and services. This system is
                                        designed to be intuitive and user-friendly, ensuring a seamless shopping
                                        experience.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${feature3})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",

                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen"
                                     className="feature-dark-screen-img-2"/>

                            </div>
                        </Grid>

                    </Grid>

                )}
            </Container>
        </div>
    );
};

export default DarkFeature2;