import React from 'react';
import {Container, Grid} from "@mui/material";

import Lightning from '../../assets/images/lightning.png'
import Gear from '../../assets/images/gear.png'
import FlutterTeam from '../../assets/images/flutter-team.png'
import './styles.css'

const Flutter = () => {
    return (
        <div className="flutter-main-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="gradient-title-wrapper">
                    <h2 className="bigger-gradient">Why Flutter?</h2>
                </div>
                <div className="flutter-inner-block">
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={5.70} style={{marginBottom: "62px"}}>
                            <div className="flutter-white">
                                <h2 className="black-inner-title">Power of Flutter</h2>
                                <p className="black-text" style={{marginBottom:"22px"}}>Flutter is a versatile and open-source framework created by
                                    Google, known for its
                                    ability to create natively compiled applications for mobile, web, and desktop from a
                                    single codebase.</p>

                                <div className="icon-wrappers" style={{
                                    backgroundImage: `url(${Lightning})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.70} style={{marginBottom: "62px"}}>
                            <div className="flutter-dark">
                                <h2 className="white-title">Fast development</h2>
                                <p className="white-text" style={{marginBottom:"22px"}}>Flutter enables rapid development, saving time and resources,
                                    and allowing quicker time-to-market.</p>
                                <div className="icon-wrappers" style={{
                                    backgroundImage: `url(${Gear})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: "10px",
                                }}>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={12} md={5.70} style={{marginBottom: "62px"}}>
                            <div className="flutter-dark">
                                <h2 className="white-title">Consistent UI</h2>
                                <p className="white-text">With Flutter, you can maintain a consistent and beautiful user
                                    interface across both iOS and Android platforms.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5.70} style={{marginBottom: "62px"}}>
                            <div className="flutter-white">
                                <h2 className="black-inner-title">Wide adoption </h2>
                                <p className="black-text">Flutter is being embraced by businesses worldwide for its
                                    efficiency and cross-platform capabilities.</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <h2 className="white-title" style={{marginTop: "-12px", marginBottom: "6px"}}>Our expertise</h2>
                <div style={{maxWidth: "982px", margin: "0 auto", marginBottom: "62px"}}>
                    <p className="white-text" style={{textAlign: "center"}}>Our team is skilled in harnessing Flutter's
                        capabilities to build stunning and
                        feature-rich mobile applications.</p>
                </div>
                <div className="flutter-icon-team">
                    <img src={FlutterTeam} alt="flutter-icons"/>
                </div>

            </Container>
        </div>
    );
};

export default Flutter;