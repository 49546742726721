import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

import App from './App';

import './index.css';
import Layout from "./components/Layout/Layout";



const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            xss: 467,
            sm: 600,
            sm1: 730,
            md: 960,
            md1:1100,
            lg: 1280,
            xl: 1920,
            // Add custom breakpoints
            xxl: 2560,
            xxxl: 3200,
        },
    },
});

const app = (
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </BrowserRouter>
)



 ReactDOM.render(app, document.getElementById('root'));