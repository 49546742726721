import React from 'react';
import {Container, Grid} from "@mui/material";

import SingleSlider from "../SingleSlider/SingleSlider";

import pic1 from "../../assets/images/pic1-min.jpeg"
import pic2 from "../../assets/images/pic2-min.jpeg"
import './styles.css';

const Team = () => {

    const slides1 = [pic1, pic2]
    const slides2 = [pic2, pic1]

    return (
        <div className="our-team-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={4}>
                        <h3 className="white-title" style={{textAlign:"left"}}>Our team</h3>
                        <p className="white-text" style={{paddingRight:"10px"}}>
                            Meet the talented individuals who make up the heart of Dartlab. Our team is composed of
                            highly skilled professionals, including developers, designers, and project managers, each
                            with a wealth of experience in their respective fields. Our collective expertise allows us
                            to tackle a wide range of IT projects, ensuring that we deliver exceptional results for our
                            clients.
                        </p>
                    </Grid>
                    <Grid item xs={12} sm={5.8} md={3.6}>
                            <SingleSlider sliderId="1" slides={slides1}/>
                    </Grid>
                    <Grid item xs={12} sm={5.8} md={3.6}>
                        <SingleSlider sliderId="2" slides={slides2}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Team;