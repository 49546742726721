import React, {useState} from 'react';
import {Grid} from "@mui/material";

import './styles.css'



const ChangeableTextBlock = ({textArr, type, color}) => {
    const [activeID, setActiveID] = useState(0);

    return (
        <div>
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={5.85} lg={4.85} style={{marginBottom:"42px"}}>
                    <div style={type === 'gettik-case' ? {display:"flex", alignItems:"flex-end", height:"100%"}:{display:"flex", padding:"23px 0", height:"100%"}}>
                        <div className="border-div" style={type==='gettik-case' ?{height:"84%"}:{}}></div>
                        {type === 'ux'? (
                            <div className="text-change-block-parent-ux">
                                {textArr.map((obj) => (
                                    <div className="text-change-block-ux" key={obj.id} onClick={()=>setActiveID(obj.id)}>
                                        {activeID === obj.id && (
                                            <div className="red-border"></div>
                                        )}
                                        <h2 className={`text-change-block-title-ux ${activeID === obj.id ? "red-text" : ""} `}>{obj.title}</h2>
                                    </div>
                                ))}

                            </div>

                        ):(
                            <div className="text-change-block-parent">
                                {textArr.map((obj) => (
                                    <div className="text-change-block" key={obj.id} onClick={()=>setActiveID(obj.id)}>
                                        {activeID === obj.id && (
                                            <div className="red-border"></div>
                                        )}
                                        {color === 'dark' ? (
                                            <h2 className={`text-change-block-title ${activeID === obj.id ? "white-text" : ""} `}>{obj.title}</h2>
                                        ):(
                                            <h2 className={`text-change-block-title ${activeID === obj.id ? "red-text" : ""} `}>{obj.title}</h2>
                                        )}
                                    </div>
                                ))}

                            </div>

                        )}

                    </div>
                </Grid>
                <Grid item xs={12} md={5.85} lg={6.7} style={{marginBottom:"42px"}}>
                    <div className="black-box">
                        <p className="white-text" style={{marginBottom:"0"}}>{textArr[activeID].text}</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangeableTextBlock;