import React from 'react';
import {Container, Grid} from "@mui/material";


import Web from "../../assets/images/web-icon.svg";
import Mobile from "../../assets/images/mobile-icon.svg";
import UX from "../../assets/images/ux_icon.svg";
import DevOps from "../../assets/images/devops_icon.svg";
import Cloud from "../../assets/images/Cloud_service_icon.svg";
import Quality from "../../assets/images/quality_icon.svg";
import DataScience from "../../assets/images/data-science.png";
import './styles.css'

const OurExpertise = () => {
    return (
        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
            <div className="our-expertise-block">
                <h2 className="black-inner-title" style={{marginBottom:"16px"}}>Our expertise</h2>
                <p className="black-text" style={{maxWidth: "730px", textAlign: "center", margin: "0 auto"}}>we
                    specialize in a wide range of technologies and areas of expertise,
                    including but not limited to:</p>
                <div className="expertise-grid-box">
                    <Grid container justifyContent={"space-between"} >
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="dark-grey expertise-box">
                                <h2 className="white-title" style={{maxWidth:"360px"}}> Web development</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={Web} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="black expertise-box">
                                <h2 className="white-title"> Mobile app development</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={Mobile} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="black expertise-box">
                                <h2 className="white-title"> UX/UI design</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={UX} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="dark-grey expertise-box">
                                <h2 className="white-title">DevOps</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={DevOps} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="dark-grey expertise-box">
                                <h2 className="white-title">Cloud services</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={Cloud} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5.65} style={{marginBottom:"62px"}}>
                            <div className="dark-grey expertise-box">
                                <h2 className="white-title">Quality assurance and testing</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={Quality} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom:"62px"}}>
                            <div className="last-box">
                                <h2 className="black-inner-title">Data science and analytics</h2>
                                <div className="expertise-img-wrapper">
                                    <img src={DataScience} alt="web"/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

export default OurExpertise;