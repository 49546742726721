import React from 'react';
import './styles.css'

const MainBlock = () => {
    return (
        <div className={"main-block"}>
                <h1>Catalyst for the growth of your business</h1>
                <button className={'main-block-btn'}>Let's talk</button>
        </div>
    );
};

export default MainBlock;