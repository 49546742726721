import React, {useState} from 'react';

import './styles.css'
import {useMediaQuery} from "react-responsive";
import {Container} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import tick from "../../../assets/images/tick.svg";
import dart from "../../../assets/images/dart.svg";
import flutter from "../../../assets/images/flutter.svg";
import django from "../../../assets/images/django.svg";
import googleMaps from "../../../assets/images/google-maps-icon.svg";
import python from "../../../assets/images/python.svg";



const ElmartStack = () => {
    const [selected, setSelected] = useState('1')
    const myArray = Array.from({ length: 13 }, (_, index) => index + 1);
    const myArray2 = Array.from({ length: 4 }, (_, index) => index + 1);
    const mobile = useMediaQuery({maxWidth:600})
    const mobile680 = useMediaQuery({maxWidth:680})
    return (
        <div>
            <div className='tech-stack'>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gradient-title-wrapper">
                        <h2 className='gradient' style={{margin: "0 0 38px"}}>Tech Stack</h2>
                    </div>
                    {mobile ? (
                        <div className="stack-slider">
                            <Swiper
                                grabCursor={true}
                                loop={true}
                                spaceBetween={10}
                                slidesPerView={2.4}
                                className="tech-swiper_container"
                                breakpoints={{
                                    // when window width is >= 320px
                                    420: {
                                        slidesPerView: 2.9,
                                        spaceBetween: 10
                                    },
                                    // when window width is >= 480px
                                    // when window width is >= 740px
                                    540: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 0
                                    },


                                }}
                            >
                                <SwiperSlide>
                                    <button id={'1'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                        <img src={tick} alt="selected" style={{display: selected === '1' ? "inline-block" : "none"}}/>
                                        Mobile Architecture
                                    </button>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <button id={'3'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                        <img src={tick} alt="selected" style={{display: selected === '3' ? "inline-block" : "none"}}/>
                                        3rd party services
                                    </button>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <button id={'4'} onClick={(e) => setSelected(e.target.id)} className="tech-tabs-button">
                                        <img src={tick} alt="selected" style={{display: selected === '4' ? "inline-block" : "none"}}/>
                                        Backend
                                    </button>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    ):(
                        <div className="tech-tabs">
                            <button id={'1'} onClick={(e) => setSelected(e.target.id)}>
                                <img src={tick} alt="selected" style={{display: selected === '1' ? "inline-block" : "none"}}/>
                                Mobile Architecture
                            </button>

                            <button id={'3'} onClick={(e) => setSelected(e.target.id)}>
                                <img src={tick} alt="selected" style={{display: selected === '3' ? "inline-block" : "none"}}/>
                                Backend
                            </button>
                            <button id={'4'} onClick={(e) => setSelected(e.target.id)}>
                                <img src={tick} alt="selected" style={{display: selected === '4' ? "inline-block" : "none"}}/>
                                3rd party services
                            </button>
                        </div>
                    )}
                    <div className='stack-parent'>
                        <div className={ selected === '1' ? 'stack-child active':'stack-child'}>
                            <img src={dart} alt="dart"/>
                            <p>Dart</p>
                        </div>
                        <div className={ selected === '1'  ? 'stack-child active':'stack-child'}>
                            <img src={flutter} alt="flutter"/>
                            <p>Flutter</p>
                        </div>

                        <div className={ selected === '3' ? 'stack-child active':'stack-child'}>
                            <img src={django} alt="django"/>
                            <p>Django</p>
                        </div>
                        <div className={ selected === '3' ? 'stack-child active':'stack-child'}>
                            <img src={python} alt="cloudflare"/>
                            <p>Python</p>
                        </div>
                        <div className={ selected === '4' ? 'stack-child active':'stack-child'}>
                            <img src={googleMaps} alt="cloudflare"/>
                            <p>Google Map API</p>
                        </div>
                        <div className='stack-child'>

                        </div>
                        <div className='stack-child'>

                        </div>
                        <div className='stack-child'>

                        </div>
                        <div className='stack-child'>

                        </div>
                        <div className='stack-child'>

                        </div>
                        <div className='stack-child'>

                        </div>

                        {mobile680 ? (
                            <>
                                {myArray2.map((i)=>(
                                    <div className='stack-child'>

                                    </div>
                                ))}
                            </>

                        ):(
                            <>
                                {myArray.map((i)=>(
                                    <div className='stack-child'>

                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default ElmartStack;