import React from 'react';
import {Container} from "@mui/material";
import ChangeableTextBlock from "../ChangableTextBlock/ChangeableTextBlock";

import './styles.css';

const textArr = [
    {
        title: "Expert Flutter Developers",
        text: "Our team includes experienced Flutter developers who can bring your ideas to life.",
        id: 0
    },
    {
        title: "Customized Solutions",
        text: "We tailor our services to your unique business requirements.",
        id: 1
    },
    {
        title: "Cross-Platform Excellence",
        text: "Leverage the full potential of Flutter for cross-platform development.",
        id: 2
    },
    {
        title: "Quality Assurance",
        text: "Our commitment to quality ensures that your app performs flawlessly.",
        id: 3
    },
    {
        title: "Client-Centric Approach",
        text: "We prioritize your success and collaborate closely to ensure your app's success.",
        id: 4
    }
];


const FlutterBenefits = () => {
    return (
        <div className=" white-bg-block ">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="gradient-title-wrapper">
                    <h2 className="bigger-gradient" style={{textAlign:"center", marginTop:"5px"}}>Benefits of choosing us for Flutter development</h2>
                </div>
                <div className="change-block-wrapper">
                    <ChangeableTextBlock textArr={textArr}/>
                </div>
            </Container>
        </div>
    );
};

export default FlutterBenefits;