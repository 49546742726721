import React, {useEffect, useRef, useState} from 'react';
import test1 from '../../assets/images/testimon1.jpg'
import test2 from '../../assets/images/testimon2.jpg'
import test3 from '../../assets/images/test3.jpg'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css'
import Card from "./Card";

const Testimonials = () => {

    const obj = {
        name: "Asel Anarbaeva",
        image: test1,
        rating: 5,
        type: 'Mobile development design',
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed pulvinar mauris. Vestibulum ac sapien nec quam congue pellentesque. Fusce eu dolor ac turpis rhoncus ornare. Sed eget risus nibh. Nam lacinia enim sed velit bibendum vehicula. Vestibulum ultrices tellus nisl, fringilla pellentesque nisl efficitur ut. In imperdiet, nisi eu bibendum sollicitudin, sapien nisl ornare nulla, et placerat augue risus id metus."
    }

    const obj2 = {
        name: "Marc Fisherman",
        image: test2,
        rating: 5,
        type: 'Web development design',
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed pulvinar mauris. Vestibulum ac sapien nec quam congue pellentesque. Fusce eu dolor ac turpis rhoncus ornare. Sed eget risus nibh. Nam lacinia enim sed velit bibendum vehicula. Vestibulum ultrices tellus nisl, fringilla pellentesque nisl efficitur ut. In imperdiet, nisi eu bibendum sollicitudin, sapien nisl ornare nulla, et placerat augue risus id metus."
    }

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const swiperRef = useRef(null);

    const handleSlideChange = () => {
        setActiveSlideIndex(swiperRef.current.swiper.realIndex);
    };


    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(2); // Index of the second slide is 1
        }
    }, []);

    return (<div>
        <div className="testimonial-swiper">
            <Swiper
                ref={swiperRef}
                initialSlide={0}
                grabCursor={true}
                loop={true}
                loopedSlides={5}
                centeredSlides={true}
                slidesPerView={1.4}
                spaceBetween={20}
                pagination={{el: '.swiper-pagination', clickable: true}}
                navigation={{
                    nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev', clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className="my_swiper_container"
                breakpoints={{

                    1800: {
                        slidesPerView: 2.3, spaceBetween: 20
                    },

                }}

            > <SwiperSlide>
                <Card obj={{...obj, image: test3}} active={false}/>
            </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj2, image: test2}} active={false}/>
                </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj2, image: test1}} active={activeSlideIndex === 0}/>
                </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj, image: test2}} active={activeSlideIndex === 1}/>
                </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj, image: test3}} active={activeSlideIndex === 2}/>
                </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj2, image: test1}} active={false}/>
                </SwiperSlide>
                <SwiperSlide>
                    <Card obj={{...obj, image: test2}} active={false}/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>);
};

export default Testimonials;