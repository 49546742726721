import React, {useEffect} from 'react';
import {Container} from "@mui/material";

import TeamProcess from "../../components/TeamProcess/TeamProcess";
import ChangeableTextBlock from "../../components/ChangableTextBlock/ChangeableTextBlock";
import CaseStudySocial from "../../components/CaseStudySocial/CaseStudySocial";
import OurExpertise from "../../components/OurExpertise/OurExpertise";
import TeamModels from "../../components/TeamModels/TeamModels";
import FAQs from "../../components/FAQs/FAQs";
import ContactUs from "../../components/ContactUs/ContactUs";

import TeamIcon from '../../assets/images/team.png'
import './styles.css';

const textArr = [
    {
        title: "Proven Track Record",
        text: "With a history of successful collaborations, we have a solid track record in team augmentation.",
        id: 0
    },
    {
        title: "Experienced Professionals",
        text: "Our professionals are seasoned experts, highly skilled in a wide range of technologies and methodologies.",
        id: 1
    },
    {
        title: "Scalability",
        text: "We offer flexible, scalable solutions to meet your specific needs.",
        id: 2
    },
    {
        title: "Quality Assurance",
        text: "We are committed to maintaining high-quality standards in all our projects.",
        id: 3
    },
    {
        title: "Client-Centric Approach",
        text: "Your satisfaction is our priority, and we tailor our services to your unique requirements.",
        id: 4
    }
];




const TeamPage = () => {
    useEffect(() => {
        window.scroll(0,0);
    }, []);
    return (
        <div>
            <div className="team-augmentation-block">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div style={{maxWidth: "982px", margin: "0 auto", textAlign: "center"}}>
                        <h2 className="white-title">Welcome to our Team augmentation services</h2>
                        <p className={"white-text"}>where we empower businesses with exceptional tech talent. Team
                            augmentation is the process of seamlessly
                            integrating our skilled professionals into your existing team, allowing you to expand your
                            capabilities
                            and achieve your goals more efficiently. </p>
                    </div>
                    <div className="team-augmentation-img-wrapper">
                        <img className="team-augmentation-img" src={TeamIcon} alt="team-icon"/>
                    </div>
                </Container>
            </div>
            <div className=" white-bg-block ">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="gradient-title-wrapper">
                        <h2 className="bigger-gradient" style={{textAlign: "center", marginTop: "5px"}}>Why choose our
                            Team augmentation services</h2>
                    </div>
                    <div className="change-block-wrapper">
                        <ChangeableTextBlock textArr={textArr}/>
                    </div>
                </Container>
            </div>
            <TeamProcess/>
            <div className="black-background">
                <CaseStudySocial/>
                <OurExpertise/>
                <div style={{textAlign:"center", padding:"42px 0 55px"}}>
                    <h2 className="white-title">
                        Team augmentation models
                    </h2>
                    <p className="white-text">We offer multiple Team augmentation models for each best use cases.</p>
                </div>
            </div>
            <TeamModels/>
            <FAQs/>
            <div style={{backgroundColor:"#161616", padding:"62px 0 0"}}>
                <ContactUs/>
            </div>
        </div>

    );
};

export default TeamPage;