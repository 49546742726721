import React from 'react';
import {Container} from "@mui/material";

import './styles.css';

const CompanyHistory = () => {
    return (
        <div className="company-history-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className="gradient-title-wrapper">
                    <h2 className="bigger-gradient">
                        Company history
                    </h2>
                </div>
            <p className="dark-text">Dartlab's journey began in 2011 when we were founded in Kyrgyzstan. Over the years, we've achieved
                significant milestones and built a reputation for excellence in the IT industry. We've expanded our
                reach and services to cater to clients globally. In 2023, Dartlab took a significant step by
                establishing a legal entity in the United States, specifically in Wyoming, solidifying our commitment to
                providing top-notch IT solutions to clients in North America and beyond.</p>
            </Container>
        </div>
    );
};

export default CompanyHistory;