import React from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import Star from '../../assets/images/star.png';
import Smile from '../../assets/images/smile.png';
import Thumb from '../../assets/images/thumb.png';
import Heart from '../../assets/images/heart.png';
import './styles.css';

const UxReasons = () => {
    const smallScreen = useMediaQuery({maxWidth: 800});

    return (
        <div>
            <div className="reason-white-box">
                <Container maxWidth={false} sx={{maxWidth: "1043px"}}>
                    <div className="reason-inner-box">
                        <div className="reason-icon-wrapper">
                            <img src={Star} alt="star-icon"/>
                        </div>
                        <div className="text-wrapper">
                            <h2 className="black-title" style={{marginBottom: "12px", textAlign: "left"}}>Expertise</h2>
                            <p className="reason-black-text">At Dartlab, our team brings top-tier skills to the design
                                table. From UI
                                specialists
                                to seasoned UX researchers, we're a powerhouse of cutting-edge expertise.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="reason-dark-box">
                <Container maxWidth={false} sx={{maxWidth: "1043px"}}>
                    <div className="reason-inner-box" style={smallScreen ? {flexDirection:"column-reverse"} : {}}>
                        <div className="text-wrapper">
                            <h2 className="white-title"
                                style={{marginBottom: "12px", textAlign: "left"}}>Experience</h2>
                            <p className="reason-white-text">With 5 years of industry leadership, our journey is a
                                testament
                                to successful collaborations, innovation, and growth across diverse sectors.</p>
                        </div>
                        <div className="reason-icon-wrapper">
                            <img src={Smile} alt="star-icon"/>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="reason-white-box">
                <Container maxWidth={false} sx={{maxWidth: "1043px"}}>
                    <div className="reason-inner-box">
                        <div className="reason-icon-wrapper">
                            <img src={Thumb} alt="star-icon"/>
                        </div>
                        <div className="text-wrapper">
                            <h2 className="black-title" style={{marginBottom: "12px"}}>Quality commitment</h2>
                            <p className="reason-black-text">At Dartlab, your success is our mission. Every project is a
                                labor of love, fueled by our passion and dedication. We invest not just our skills but
                                our
                                hearts, ensuring each design surpasses expectations. With Dartlab, expect a commitment
                                that
                                goes beyond requirements, delivering results with genuine care and quality.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="reason-dark-box">
                <Container maxWidth={false} sx={{maxWidth: "1043px"}}>
                    <div className="reason-inner-box" style={smallScreen ? {flexDirection:"column-reverse"} : {}}>
                        <div className="text-wrapper">
                            <h2 className="white-title" style={{marginBottom: "12px", textAlign: "left"}}>Client
                                testimonial</h2>
                            <p className="reason-white-text">Discover what our clients say about us. Their satisfaction
                                reflects not just our design prowess but our knack for understanding and exceeding
                                client
                                expectations.</p>
                        </div>
                        <div className="reason-icon-wrapper">
                            <img src={Heart} alt="star-icon"/>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default UxReasons;