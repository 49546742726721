import React from 'react';
import './styles.css'
import {Container} from "@mui/material";
import telegram from '../../assets/images/telegram.svg';
import signal from '../../assets/images/signal.svg';
import whatsapp from '../../assets/images/whatsapp.svg'

const Footer = () => {

    const pages = [
        {name: 'Company', route: "/"},
        {name: 'Services', route: "/"},
        {name: 'For startups', route: "/"},
        {name: 'Team augmentation', route: "/"},
    ];

    return (
        <div className="footer">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className='footer-social'>
                    <p>Let's talk about business</p>
                    <div className={'social-links'}>
                        <img src={telegram} alt="telegram"/>
                        <img src={signal} alt="signal"/>
                        <img src={whatsapp} alt="whatsapp"/>
                    </div>
                </div>
                <div className='footer-page-box'>
                    {pages.map(page => (
                        <button
                            key={page.name}
                            className={"footer-page-link"}
                        >
                            {page.name}
                        </button>
                    ))}
                </div>
                <div className="footer-last-box">
                    <p>© 2023 DartLab. All rights reserved..</p>
                    <p>Non-Disclosure Agreement (NDA)</p>
                    <p>Privacy Policy</p>
                    <p>FAQ</p>
                </div>
            </Container>
        </div>
    );
};

export default Footer;