import React from 'react';
import {Container, Grid} from "@mui/material";


import './styles.css'
import gettikFeature from "../../../assets/images/gettik-feature-screen.svg";
import gettikMapMarker from "../../../assets/images/gettik-map-marker.svg";
import gettikFeature2 from "../../../assets/images/gettik-feature-screen-2.svg";
import gettikChat from "../../../assets/images/chat-icons.svg";
import gettikFeature3 from "../../../assets/images/gettik-feature-screen3.svg";
import gettikCard from "../../../assets/images/credit-card-icon.svg";
import gettikFeature4 from "../../../assets/images/gettik-feature-screen-4.svg";
import gettikRating from "../../../assets/images/gettikRating.svg";
import {useMediaQuery} from "react-responsive";


const MainFeatures = () => {
    const max730 = useMediaQuery({maxWidth: 730});
    const max650 = useMediaQuery({maxWidth: 650});
    return (
        <>
            <div className="main-features-block">
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>

                    {max650 ? (
                        <div className="main-features-block-inner-small">
                            <h2 className="main-features-block-title">Main features of the project</h2>
                        </div>
                    ) : (
                        <div className="main-features-block-inner">
                            <h2 className="main-features-block-title">Main features of the project</h2>
                        </div>
                    )}

                </Container>
            </div>
            <div className="main-features-dark-box">
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    <div>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm1={6.3} lg={8}>
                                <div style={{maxWidth: "748px"}}>
                                    <h2 className="main-features-dark-box-title">
                                        Google APIs integration
                                    </h2>
                                    <p className="main-features-dark-box-text">
                                        We integrated Google APIs for calculating travel times and distances between
                                        multiple
                                        points, this
                                        API enables the app to efficiently plan routes and estimate arrival times and
                                        budget. This
                                        feature
                                        is integral in optimizing carpooling schedules and routes, ensuring users can
                                        plan
                                        their
                                        trips with
                                        confidence
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm1={5.7} lg={4}>
                                {max730 ? (
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature} alt="gettikFeature"
                                             className="gettik-feature-screen-small"/>
                                        <img src={gettikMapMarker} alt="map-marker"
                                             className="gettik-map-marker-img-small"/>
                                    </div>
                                ) : (
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature} alt="gettikFeature" className="gettik-feature-screen"/>

                                        <img src={gettikMapMarker} alt="map-marker" className="gettik-map-marker-img"/>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className="main-features-white-box">
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    <div>
                        {max730 ? (
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm1={6.3} lg={7}>
                                    <div style={{maxWidth: "748px"}}>
                                        <h2 className="main-features-white-box-title">
                                            Real-time chatting
                                        </h2>
                                        <p className="main-features-white-box-text">
                                            To keep communication seamless and immediate, our app integrates Firebase's
                                            real-time messaging API. This allows users to communicate with their
                                            potential
                                            ride-mates without leaving the app. Whether it's discussing pick-up points,
                                            sharing updates, or making last-minute changes, the chat feature keeps
                                            everyone
                                            informed and connected throughout their journey.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm1={5.7} lg={4}>
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature2} alt="gettikFeature"
                                             className="gettik-feature-screen2-small"/>
                                        <img src={gettikChat} alt="chat-icon" className="gettik-chat-img-small"/>
                                    </div>
                                </Grid>
                            </Grid>

                        ) : (
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm1={5.7} lg={4}>
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature2} alt="gettikFeature"
                                             className="gettik-feature-screen2"/>
                                        <img src={gettikChat} alt="chat-icon" className="gettik-chat-img"/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm1={6.3} lg={7}>
                                    <div style={{maxWidth: "748px"}}>
                                        <h2 className="main-features-white-box-title">
                                            Real-time chatting
                                        </h2>
                                        <p className="main-features-white-box-text">
                                            To keep communication seamless and immediate, our app integrates Firebase's
                                            real-time messaging API. This allows users to communicate with their
                                            potential
                                            ride-mates without leaving the app. Whether it's discussing pick-up points,
                                            sharing updates, or making last-minute changes, the chat feature keeps
                                            everyone
                                            informed and connected throughout their journey.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>

                        )}
                    </div>
                </Container>
            </div>
            <div className="main-features-dark-box">
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    <div>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm1={6.3} lg={8}>
                                <div style={{maxWidth: "748px"}}>
                                    <h2 className="main-features-dark-box-title">
                                        Payment gateway integration
                                    </h2>
                                    <p className="main-features-dark-box-text">
                                        Incorporates a secure payment system that supports multiple payment methods,
                                        ensuring easy and safe transactions directly within the app.
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm1={5.7} lg={4}>
                                {max730 ? (
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature3} alt="gettikFeature" className="gettik-feature-screen3-small"/>
                                        <img src={gettikCard} alt="credit-card" className="gettik-credit-card-small"/>
                                    </div>
                                ) : (
                                    <div style={{position: "relative"}}>
                                        <img src={gettikFeature3} alt="gettikFeature" className="gettik-feature-screen3"/>
                                        <img src={gettikCard} alt="credit-card" className="gettik-credit-card"/>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className="main-features-white-box white-feature-box-2">
                <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                    {max730 ? (
                        <div>
                            <Grid container justifyContent="space-between">

                                <Grid item xs={12} sm1={6.3} lg={6.5}>
                                    <div style={{maxWidth: "748px"}}>
                                        <h2 className="main-features-white-box-title">
                                            User Profile and Rating System
                                        </h2>
                                        <p className="main-features-white-box-text">
                                            Features comprehensive user profiles and a rating system that encourages trust
                                            and safety. Users can rate each other after a ride, which helps maintain a high
                                            standard of conduct and service within the community.
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm1={5.7} lg={4}>
                                        <div style={{position: "relative"}}>
                                            <img src={gettikFeature4} alt="gettikFeature" className="gettik-feature-screen4-small"/>
                                            <img src={gettikRating} alt="credit-card" className="gettik-rating-img-small"/>
                                        </div>
                                </Grid>
                            </Grid>
                        </div>

                    ):(
                        <div style={{paddingTop:"80px"}}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm1={5.7} lg={4}>
                                        <div style={{position: "relative"}}>
                                            <img src={gettikFeature4} alt="gettikFeature" className="gettik-feature-screen4"/>
                                            <img src={gettikRating} alt="rating-img" className="gettik-rating-img"/>
                                        </div>
                                </Grid>
                                <Grid item xs={12} sm1={6.3} lg={6.5}>
                                    <div style={{maxWidth: "748px"}}>
                                        <h2 className="main-features-white-box-title high-title">
                                            User Profile and Rating System
                                        </h2>
                                        <p className="main-features-white-box-text">
                                            Features comprehensive user profiles and a rating system that encourages trust
                                            and safety. Users can rate each other after a ride, which helps maintain a high
                                            standard of conduct and service within the community.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </Container>
            </div>
        </>
    )
        ;
};

export default MainFeatures;