import React from 'react';
import {Container, Grid} from "@mui/material";
import {useNavigate} from "react-router-dom";

import Devices from "../../assets/images/devices.png";
import Flag from "../../assets/images/kg-flag.png";
import './styles.css';
import {useMediaQuery} from "react-responsive";
import SliderMobile from "../SliderMobile/SliderMobile";

const Gettik = () => {
    const navigate = useNavigate();
    const isAbove800 = useMediaQuery({maxWidth: 800});
    const isAbove700 = useMediaQuery({maxWidth: 700});
    const isAbove580 = useMediaQuery({maxWidth: 580});

    return (
        <div className="gettik-block">
            <div className="delivery">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={5.85}>
                            <div>
                                <h3 className="just-gradient startup-delivery-title">Startup delivery overview</h3>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5.2}>
                            <p>
                                See the results of MVP development we’ve done for one of our clients to build a mobile
                                app for Android and iOS.
                            </p>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="gettik-inner"
                 style={{
                     background: `linear-gradient(77deg, #24D07A 0%, #002B0E 100%)`,
                     backgroundPosition: 'right',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                {!isAbove700 && (
                    <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                        <div style={{position: "relative", height: "100%"}}>
                            <div className="gettik-title-wrapper">
                                <div className="country-wrapper">
                                    <img src={Flag} alt="kyrgyz-flag"/> <span>Kyrgyzstan</span>
                                </div>
                                <h2 className="gettik-title">Gettik</h2>
                            </div>
                            <Grid container style={{maxWidth: isAbove800 ? "270px" : "335px"}}
                                  justifyContent={"space-between"}>
                                <div className="platform-box">
                                    <h4>Platforms</h4>
                                    <p>iOS, Android</p>
                                </div>
                                <div className="platform-box">
                                    <h4>Industry</h4>
                                    <p>Transportation</p>
                                </div>
                            </Grid>
                            <button className="see-btn styled-button" onClick={() => navigate('/gettik-case')}>See case
                                study
                            </button>
                        </div>
                    </Container>
                )}

                {!isAbove700 && (
                    <img src={Devices} alt="Devices" className="gettik-imgs"/>
                )}

                {isAbove700 && (
                    <>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                            <div>
                                <div style={{
                                    position: "relative",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    flexDirection: isAbove580 ? 'column' : "row"
                                }}>
                                    <div className="gettik-title-wrapper">
                                        <div className="country-wrapper">
                                            <img src={Flag} alt="kyrgyz-flag"/> <span>Kyrgyzstan</span>
                                        </div>
                                        <h2 className="gettik-title">Gettik</h2>
                                    </div>
                                    <Grid container style={{maxWidth: isAbove800 ? "270px" : "335px"}}
                                          justifyContent={"space-between"}>
                                        <div className="platform-box">
                                            <h4>Platforms</h4>
                                            <p>iOS, Android</p>
                                        </div>
                                        <div className="platform-box">
                                            <h4>Industry</h4>
                                            <p>Transportation</p>
                                        </div>
                                    </Grid>
                                </div>
                                {/*<button className="see-btn see-btn-mobile styled-button" onClick={() => navigate('/gettik-case')}>See case study</button>*/}

                            </div>


                        </Container>

                        <div style={{position: "relative", marginTop: "60px"}}>
                            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                                <div className="case-transport-slider" style={{marginBottom: "5px"}}>
                                </div>
                            </Container>
                            <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                                <SliderMobile/>
                            </Container>
                        </div>
                    </>

                )}


                {isAbove700 && (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "auto"}}>
                        <button className=" styled-button" style={{maxWidth: "286px", width: "100%"}}
                                onClick={() => navigate('/gettik-case')}>See case study
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Gettik;