import React from 'react';
import {Container, Grid} from "@mui/material";

import userPic from "../../assets/images/ux-step-img-min.jpeg";
import wireframe from "../../assets/images/ux-wireframe.jpeg";
import prototype from "../../assets/images/ux-prototype.png";
import usability from "../../assets/images/ux-usability.jpeg";
import './styles.css';


const UxService = () => {
    return (
        <div>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className="white-title" style={{marginBottom: "42px"}}>Service offerings</h2>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-dark">
                            <div className="gradient-title-wrapper">
                                <h2 className="step-gradient-title">
                                    Usability Testing
                                </h2>
                            </div>
                            <p className="step-white-text">
                                Through extensive user research, we uncover user behaviors, needs, and pain points. This
                                invaluable information forms the foundation of our design strategy, allowing us to
                                tailor experiences that truly resonate with your users.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-img-box"
                             style={{
                                 backgroundImage: `url(${userPic})`,
                                 backgroundSize: 'cover',
                                 backgroundColor: "#161616",
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-img-box"
                             style={{
                                 backgroundImage: `url(${wireframe})`,
                                 backgroundSize: 'cover',
                                 backgroundColor: "#161616",
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-white">
                            <h2 className="step-black-title">
                                Wireframing
                            </h2>
                            <p className="step-black-text">
                                Before the pixels, there's the blueprint. We believe in the power of structured design,
                                and that's where wireframing comes into play. Crafting wireframes allows us to map out
                                the skeletal framework of your interface, ensuring that the hierarchy and structure
                                align seamlessly with user expectations and business goals.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-dark">
                            <h2 className="step-white-title">
                                Prototyping
                            </h2>
                            <p className="step-white-text">
                                Seeing is believing, especially in the realm of UI/UX design. Our prototyping phase
                                brings static designs to life, creating interactive models that allow you to experience
                                the flow and functionality of the final product. This iterative process ensures that any
                                refinements can be made early in the design stage, saving time and resources in the long
                                run.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-img-box"
                             style={{
                                 backgroundImage: `url(${prototype})`,
                                 backgroundSize: 'cover',
                                 backgroundColor: "#161616",
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-img-box"
                             style={{
                                 backgroundImage: `url(${usability})`,
                                 backgroundSize: 'cover',
                                 backgroundColor: "#161616",
                                 backgroundPosition: 'center',
                                 backgroundRepeat: "no-repeat",
                             }}
                        >
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5.70} style={{marginBottom: "62px"}}>
                        <div className="step-white">
                            <div className="gradient-title-wrapper">
                                <h2 className="step-gradient-title">
                                    Usability Testing
                                </h2>
                            </div>

                            <p className="step-black-text">
                                Describe your process for evaluating the effectiveness and user-friendliness of designs.
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default UxService;