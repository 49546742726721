import React from 'react';
import {Container} from "@mui/material";


import './styles.css';
import GooglePlayIcon from '../../../assets/images/google_play_icon.svg'
import rating from '../../../assets/images/4.5 raiting.svg'
import gettikScreens from "../../../assets/images/gettik screens.png";
import {useMediaQuery} from "react-responsive";
import SliderMobile from "../../SliderMobile/SliderMobile";

const GettikInfo = () => {

    const max700 = useMediaQuery({maxWidth: 700});

    return (
        <div>
            {max700 ? (

                <div className="gettik-inner"
                     style={{
                         background: `linear-gradient(77deg, #24D07A 0%, #002B0E 100%)`,
                         backgroundPosition: 'right',
                         backgroundRepeat: "no-repeat",
                     }}
                >
                    <Container maxWidth={false} sx={{maxWidth: "1248px"}}>

                        <div style={{display:"flex", justifyContent:"space-evenly", marginBottom:"32px"}} className="gettik-info-title-wrapper">
                            <div>
                                <h2 className="gettik-title-info">
                                    Gettik
                                </h2>
                                <p className="country-name">Kyrgyzstan</p>
                            </div>

                            <img src={rating} alt="rating"/>
                        </div>

                        <div style={{margin:"24px 0", display:"flex", flexDirection:"column", alignItems:"center"}}>
                            <p className="available-on">Now available on</p>
                            <div className="gettik-info-box">
                                <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                <p> Google Play and AppStore</p>
                            </div>
                        </div>

                    </Container>

                    <div style={{position: "relative", marginTop:"20px"}}>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                            <div className="case-transport-slider" style={{marginBottom:"5px"}}>
                            </div>
                        </Container>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                            <SliderMobile/>
                        </Container>
                    </div>

                </div>
            ):(

                <div className="gettik-info">


                    <img src={gettikScreens} alt="gettikScreens" className="gettik-screens-img"/>

                    <Container maxWidth={false} sx={{maxWidth: "1248px"}}>

                        <h2 className="gettik-title-info">
                            Gettik
                        </h2>
                        <p className="country-name">Kyrgyzstan</p>
                        <div style={{margin:"44px 0"}}>
                            <p className="available-on">Now available on</p>
                            <div className="gettik-info-box">
                                <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                <p> Google Play and AppStore</p>
                            </div>
                        </div>


                        <img src={rating} alt="rating"/>
                    </Container>


                </div>

            )}
        </div>
    );
};

export default GettikInfo;