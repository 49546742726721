import React from 'react';
import {Container} from "@mui/material";

import pic1 from '../../assets/images/model-1-min.jpeg';
import pic2 from '../../assets/images/model-2-min.jpeg';
import pic3 from '../../assets/images/model-3-min.jpeg';
import './styles.css';

const TeamModels = () => {
    return (
        <div>
            <div className="team-box" style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${pic1})`,
                backgroundSize: "cover",
                backgroundPosition:" 0 40%"
            }}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="team-mod">
                        <div style={{maxWidth:"327px"}}>
                                <h2 className="white-title" style={{marginBottom: "10px"}} >Dedicated team</h2>
                            <p className="white-text" >A dedicated team working exclusively on your project.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="team-box" style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${pic2})`,
                backgroundSize: "cover",
                backgroundPosition:" 0 40%"
            }}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="team-mod" style={{justifyContent:"flex-start"}}>
                        <div style={{maxWidth:"327px"}}>
                            <h2 className="white-title" style={{marginBottom: "10px"}}>Project-based</h2>
                            <p className="white-text">Professionals joining your project for specific tasks.</p>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="team-box" style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${pic3})`,
                backgroundSize: "cover",
                backgroundPosition:" 0 40%"
            }}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="team-mod">
                        <div style={{maxWidth:"388px"}}>
                            <h2 className="white-title">Temporary staffing</h2>
                            <p className="white-text">Short-term resource additions to address skill gaps.</p>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default TeamModels;