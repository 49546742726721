import React from 'react';
import {Container} from "@mui/material";
import videoBackground from '../../assets/videos/back-video.mp4';
import videoBackgroundWebM from '../../assets/videos/back-video.webm';
import videoBackgroundOgg from '../../assets/videos/back-video.ogg';
import ecommerce from '../../assets/images/E-commerce.svg';
import transport from '../../assets/images/transport.svg';
import gps from '../../assets/images/gps.svg';
import logistic from '../../assets/images/logistic.svg';
import market from '../../assets/images/marketplace.svg';
import media from '../../assets/images/media.svg';
import realEstate from '../../assets/images/realEstate.svg';
import crm from '../../assets/images/crm.svg';
import saas from '../../assets/images/saas.svg';
import travel from '../../assets/images/travel.svg';
import retail from '../../assets/images/reatil.svg';
import banking from '../../assets/images/banking.svg';

const Expertise = () => {

    return (
        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
            <div className='expertise-block'>
                <div className="video-container">
                    <video autoPlay loop muted>
                        <source src={videoBackground} type="video/mp4" />
                        <source src={videoBackgroundWebM} type="video/webm" />
                        <source src={videoBackgroundOgg} type="video/ogg" />
                    </video>
                    <div className="content">
                        <div className="gradient-title-wrapper">
                            <h1 className='gradient content-gradient'>Expertise</h1>
                        </div>
                        <p className='expertise-block-text'>We have experience in creating projects for</p>
                        <div className="expertise-box-parent"
                             id={"expertise-box"}>
                            <div className={"expertise-p-box box1"}>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={ecommerce} alt="ecommerce"/>
                                    </div>
                                    <p>Ecommerce</p>
                                </div>

                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={transport} alt="transport"/>
                                    </div>
                                    <p>Transportation</p>
                                </div>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={gps} alt="gps"/>
                                    </div>
                                    <p>GPS and map</p>
                                </div>
                            </div>

                            <div className={"expertise-p-box box2"}>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={logistic} alt="logistic"/>
                                    </div>
                                    <p>Logistics</p>
                                </div>

                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={market} alt="market"/>
                                    </div>
                                    <p>Marketplaces</p>
                                </div>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={media} alt="media"/>
                                    </div>
                                    <p>Media</p>
                                </div>
                            </div>


                            <div className={"expertise-p-box box3"}>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={realEstate} alt="real"/>
                                    </div>
                                    <p>Real estate</p>
                                </div>

                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={crm} alt="crm"/>
                                    </div>
                                    <p>CRM development</p>
                                </div>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={saas} alt="saas"/>
                                    </div>
                                    <p>SaaS development</p>
                                </div>
                            </div>

                            <div className={"expertise-p-box box4"}>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={travel} alt="travl"/>
                                    </div>
                                    <p>Travel</p>
                                </div>

                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={retail} alt="retail"/>
                                    </div>
                                    <p>Retail</p>
                                </div>
                                <div className="expertise-row">
                                    <div className="expertise-icon-wrapper">
                                        <img src={banking} alt="banking"/>
                                    </div>
                                    <p>Banking</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Expertise;