import React from 'react';
import {Container, Grid} from "@mui/material";

import './styles.css';
import thumbsUp from "../../assets/images/thumbsUp-removebg.png";
import trip from "../../assets/images/trip.svg";
import account from "../../assets/images/account.svg";
import lifeChat from "../../assets/images/life-chat.svg";
import rating from "../../assets/images/rating.svg";
import {useMediaQuery} from "react-responsive";

const ProjectIdea = () => {
    const maxSm1 = useMediaQuery({maxWidth: 730});
    const maxMd = useMediaQuery({maxWidth: 960});

    return (
        <div style={{backgroundColor: "white"}}>
            <Container maxWidth={false} sx={{maxWidth: "1248px"}}>

                <div className="project-idea-block">
                    <Grid container justifyContent={"space-between"} style={ maxMd ? {} :{marginBottom: "32px"}}>
                        <Grid item xs={12} md={6.85}>
                            <div className="project-idea-big-box">
                                <h3>Project idea</h3>
                                <p>Gettik is a public project (platform) for quickly and conveniently finding a
                                    co-traveler. Travel sharing can be one of the best solutions when there is no other
                                    means of transportation to a specific location. Gettik is committed to promoting car
                                    sharing by targeting people in Kyrgyzstan (with growth potential in other markets),
                                    making it easier for them to join and use this platform.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.85} container flexDirection="column" justifyContent="space-between">
                            <div className="project-box box-1">
                                <h3>Time to market</h3>
                                <p>3 months</p>
                            </div>
                            <div className="project-box box-2">
                                <h3>Target audience</h3>
                                <p>People with ride sharing experience</p>
                            </div>
                            <div className="project-box box-3">
                                <h3>Project Status</h3>
                                <p>Ongoing development</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm1={5.49}  md={7.5} style={maxSm1 ? {marginBottom:"32px"}:{}}  md1={5.49}>
                            <div className="features-box">
                                <h3 className="features-title">Features</h3>
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12} xss={4.97} sm1={12} md={4.97}>
                                        <div className="feature-box">
                                            <img src={trip} alt="trip"/>
                                            <p>Trip creating</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} xss={6.29} sm1={12} md={6.29} >
                                        <div className="feature-box">
                                            <img src={account} alt="account"/>
                                            <p>Personal Account</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} xss={4.97} sm1={12} md={4.97}>
                                        <div className="feature-box">
                                            <img src={rating} alt="rating"/>
                                            <p>Rating system</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} xss={6.29} sm1={12} md={6.29} >
                                        <div className="feature-box">
                                            <img src={lifeChat} alt="lifeChat"/>
                                            <p>Live chat</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6.19} md={4}  md1={6.19} container flexDirection="column" justifyContent="space-between">
                            <div className="result-box">
                                <h3 className="result-title">Result</h3>
                                    <p className="result-number">1000+</p>
                                <p className="result-text">installs in the first week</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>

        </div>
    );
};

export default ProjectIdea;