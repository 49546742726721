import React from 'react';
import {useMediaQuery} from "react-responsive";
import {Container, Grid} from "@mui/material";
import feature4 from "../../../assets/images/sporthorse-feature-4.png";
import featureScreen1 from "../../../assets/images/sporthorse-feature-screen-1.svg";

import './styles.css'

const Feature2 = () => {
    const max730 = useMediaQuery({maxWidth: 730});
    return (
        <div className="dark-features">
            <Container maxWidth={false} sx={{maxWidth: "1684px"}} disableGutters={true}>
                {max730 ? (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <Container maxWidth={false} sx={{maxWidth: "624px"}}>
                                    <div className="feature-white-small">
                                        <h2 className="feature-black-title">Audio Streaming</h2>
                                        <p className="feature-black-text">
                                            Sporthorse features live audio streaming, allowing users to tune into
                                            horse-related
                                            radio broadcasts. This innovative feature caters to enthusiasts seeking the
                                            latest
                                            news, music, and commentary in the equestrian world.</p>
                                    </div>
                                </Container>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${feature4})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "660px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen"
                                     className="feature-dark-screen-img"/>

                            </div>
                        </Grid>
                    </Grid>

                ) : (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url(${feature4})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen"
                                     className="feature-dark-screen-img"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <div className="feature-white-box">
                                    <h2 className="feature-black-title">Referral System</h2>
                                    <p className="feature-black-text">
                                        A novel referral system where users can share stickers related to businesses on
                                        their photos. This gamification aspect rewards users with bonuses for views and
                                        likes, which can be redeemed for products or services.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                )}
            </Container>
        </div>
    );
};

export default Feature2;