import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css'

import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import screen1 from '../../assets/images/screen1.png'
import screen2 from '../../assets/images/screen2.png'
import screen3 from '../../assets/images/screen3.png'
import screen4 from '../../assets/images/screen4.png'
import screen5 from '../../assets/images/screen5.png'

const CaseMarketSlider = () => {
    return (
        <div className="market-container">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                autoplay={{delay: 4000}}
                spaceBetween={10}
                slidesPerView={1.8}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 10,
                    depth: 230,
                    modifier: 2.6   ,
                }}
                pagination={{el: '.swiper-pagination', clickable: true}}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"

                breakpoints={{
                    // when window width is >= 320px

                    380: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 233,
                            modifier: 2.6,
                        }
                    },


                    400: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 233,
                            modifier: 2.6,
                        }
                    },


                    415: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 233,
                            modifier: 2.65,
                        }
                    },

                    500: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 275,
                            modifier: 2.4,
                        }
                    },
                    700: {
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 10,
                            depth: 280,
                            modifier: 2.4,
                        }
                    },
                    // when window width is >= 480px
                    // 480: {
                    //     slidesPerView: 1.3,
                    //     spaceBetween: 10
                    // },

                }

                }
            >
                <SwiperSlide>
                    <img src={screen1} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screen2} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screen3} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screen4} alt="slide_image" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={screen5} alt="slide_image" />
                </SwiperSlide>


                {/*<div className="slider-controler">*/}
                {/*    <div className="swiper-button-prev slider-arrow">*/}
                {/*        <ion-icon name="arrow-back-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*    <div className="swiper-button-next slider-arrow">*/}
                {/*        <ion-icon name="arrow-forward-outline"></ion-icon>*/}
                {/*    </div>*/}
                {/*    <div className="swiper-pagination"></div>*/}
                {/*</div>*/}
            </Swiper>
        </div>
    );
};

export default CaseMarketSlider;



