import React, {useEffect} from 'react';
import {Container} from "@mui/material";

import Team from "../../components/Team/Team";
import CompanyHistory from "../../components/CompanyHistory/CompanyHistory";
import CompanyCulture from "../../components/CompanyCulture/CompanyCulture";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactUs from "../../components/ContactUs/ContactUs";

import textImg from '../../assets/images/dartlab-text.svg'
import '../styles.css'



const Company = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, []);

    return (
        <>
            <div className="company-main-block" style={{
                backgroundImage: `url(${textImg})`,
                backgroundSize: 'contain',
                backgroundPosition: 'bottom',
                backgroundRepeat:"no-repeat",
            }}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="company-main-inner">
                        <h2 className="bigger-gradient ">About us</h2>
                        <p className="">Dartlab is an innovative IT company that was founded in 2011 in Kyrgyzstan. We have a rich history of providing cutting-edge technology solutions to businesses worldwide. Our mission is to create exceptional IT solutions that drive our clients' success. At Dartlab, we're driven by a set of core values that include a commitment to excellence, client satisfaction, and continuous innovation.</p>
                    </div>
                </Container>
            </div>
            <Team/>
            <CompanyHistory/>
            <CompanyCulture/>
            <div className="company-testimonials testimonials" style={{marginBottom:"0"}}>
                <h3 className="white-title" style={{marginTop:"-10px", marginBottom:"46px"}}>Testimonials</h3>
                <Testimonials/>
            </div>
            <div style={{paddingTop:"22px", backgroundColor:"#161616"}}>
                <ContactUs/>
            </div>
        </>

    );
};

export default Company;