import React from 'react';
import {useMediaQuery} from "react-responsive";
import {Container, Grid} from "@mui/material";


import './styles.css';
import oshbus1 from "../../../assets/images/oshbus-feature-1.png";
import oshbus2 from "../../../assets/images/oshbus-feature-2.svg";
import oshbus3 from "../../../assets/images/oshbus-feature-3.svg";
import oshbus4 from "../../../assets/images/oshbus-feature-4.png";
import skyscrapers from "../../../assets/images/oshbus-planning.png";


const OshbusFeatures = () => {
    const max730 = useMediaQuery({maxWidth: 730});


    return (
        <div style={{paddingTop: "42px"}}>
            <div className="oshbus-feature-1">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <h2 className="oshbus-main-feature-title">Main features of the project</h2>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm1={6.5} lg={5.85} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="oshbus-feature-box-1">
                                <h3 className="oshbus-feature-title">Synchronous Use of Multiple Databases</h3>
                                <p style={{marginBottom: "42px"}}>OshBus integrates multiple databases in real-time to
                                    manage a vast amount of data,
                                    including bus routes, schedules, and user interactions. This approach ensures data
                                    consistency and reliability across the platform.
                                </p>
                                <p><span>Impact:</span> Enables the app to provide users with accurate and up-to-date
                                    information,
                                    enhancing the reliability of public transport services.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={5.25} lg={5.85}>
                            <div className="oshbus-feature-img-wrapper"
                            >
                                <img src={oshbus1} alt="oshbus-screen" className="oshbus-feature-img"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="oshbus-feature-1"
                 style={{
                     backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), url(${skyscrapers})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'bottom',
                     backgroundRepeat: "no-repeat",
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between" alignItems="center"
                          flexDirection={max730 ? "column-reverse" : "row"}>
                        <Grid item xs={12} sm1={5.25} lg={5}>
                            <div className="oshbus-feature-2-img-wrapper"
                            >
                                <img src={oshbus2} alt="oshbus-screen" className="oshbus-feature-2-img"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6.5} lg={5.85} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="oshbus-feature-box-1">
                                <h3 className="oshbus-feature-title">GPS Tracker Integration</h3>
                                <p style={{marginBottom: "42px"}}>Each bus is equipped with a GPS tracker, allowing for
                                    real-time tracking of bus
                                    locations. This feature is central to the app's ability to offer live updates and
                                    arrival times to users.
                                </p>
                                <p><span>Impact:</span> Dramatically improves the user experience by reducing wait times
                                    and
                                    uncertainty, making public transport more appealing and efficient.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="oshbus-feature-1" style={{paddingTop: "60px",}}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm1={6.5} lg={5.85} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="oshbus-feature-box-1">
                                <h3 className="oshbus-feature-title">Smart CMS System</h3>
                                <p style={{marginBottom: "42px"}}>The app features a sophisticated Content Management
                                    System (CMS) designed for easy updating
                                    and management of bus routes, stops, and schedules by transport authorities.
                                </p>
                                <p><span>Impact:</span> Streamlines operational management, making it easier to adjust
                                    services to meet user
                                    needs and handle city growth or changes in transport demand.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={5.25} lg={5.85}>
                            <div className="oshbus-feature-2-img-wrapper"
                            >
                                <img src={oshbus3} alt="oshbus-screen" className="oshbus-feature-3-img"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="oshbus-feature-1"
                 style={{
                     backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), url(${skyscrapers})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'bottom',
                     backgroundRepeat: "no-repeat",
                     paddingTop: "20px",
                 }}
            >
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between" alignItems="center"
                          flexDirection={max730 ? "column-reverse" : "row"}>
                        <Grid item xs={12} sm1={5.25} lg={5}>
                            <div className="oshbus-feature-2-img-wrapper"
                            >
                                <img src={oshbus4} alt="oshbus-screen" className="oshbus-feature-4-img"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6.5} lg={5.85} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="oshbus-feature-box-1">
                                <h3 className="oshbus-feature-title">Efficient Server Load Distribution</h3>
                                <p style={{marginBottom: "42px"}}>OshBus employs advanced server load distribution
                                    techniques to manage the influx of
                                    real-time data and user requests smoothly, ensuring the app remains responsive at
                                    all
                                    times.
                                </p>
                                <p><span>Impact:</span> Guarantees high performance and availability of the service,
                                    even during peak
                                    usage times, thus maintaining a seamless user experience.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="oshbus-feature-1" style={{padding: "60px 0",}}>
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm1={6.5} lg={5.85} style={max730 ? {marginBottom: "32px"} : {}}>
                            <div className="oshbus-feature-box-1">
                                <h3 className="oshbus-feature-title">Optimized Handling of High Request Volumes</h3>
                                <p style={{marginBottom: "42px"}}>The platform is optimized to handle high volumes of
                                    requests efficiently, thanks to its
                                    robust backend architecture. This ensures that users receive timely responses and
                                    updates, even under heavy load.
                                </p>
                                <p><span>Impact:</span> Ensures the app can scale to support a growing user base without
                                    compromising on
                                    speed or reliability, crucial for user satisfaction and engagement.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={5.25} lg={5.85}>
                            <div className="oshbus-feature-2-img-wrapper"
                            >
                                <img src={oshbus3} alt="oshbus-screen" className="oshbus-feature-3-img"/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </div>
    );
};

export default OshbusFeatures;