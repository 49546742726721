import React from 'react';
import {Container} from "@mui/material";

import './styles.css';
import bgImg from "../../assets/images/startup.jpg";

const GetConsultation = () => {
    return (
        <div className="get-consultation-block"
        style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.50) 100%), url(${bgImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat:"no-repeat",
        }}
        >
            <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                <div className="get-consultation-inner-block">
                    <h3 className="get-consultation-title">
                        Get a Minimum Viable Product to collect first feedback, validate assumptions, and pitch your
                        idea to
                        investors
                    </h3>

                    <p>Get consulted by our specialists to validate your project idea</p>

                    <button className="styled-button">Free consultation</button>
                </div>
            </Container>
        </div>
    );
};

export default GetConsultation;