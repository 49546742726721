import React from 'react';

import './styles.css';
import {Container, Grid} from "@mui/material";
import feature2 from "../../../assets/images/sporthorse-feature-2.png";
import featureScreen1 from "../../../assets/images/sporthorse-feature-screen-1.svg";
import {useMediaQuery} from "react-responsive";

const DarkFeature1 = () => {
    const max730 = useMediaQuery({maxWidth: 730});
    return (
        <div className="dark-features">
            <Container maxWidth={false} sx={{maxWidth: "1684px"}} disableGutters={true}>
                {max730 ? (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <Container maxWidth={false} sx={{maxWidth: "624px"}}>

                                <div className="feature-dark-small">
                                    <h2 className="feature-white-title">Audio Streaming</h2>
                                    <p className="feature-white-text">
                                        Sporthorse features live audio streaming, allowing users to tune into horse-related
                                        radio broadcasts. This innovative feature caters to enthusiasts seeking the latest
                                        news, music, and commentary in the equestrian world.</p>
                                </div>
                                </Container>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${feature2})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "660px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen" className="feature-dark-screen-img"/>

                            </div>
                        </Grid>
                    </Grid>

                ) : (
                    <Grid container>
                        <Grid item xs={12} sm1={6}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${feature2})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >

                                <img src={featureScreen1} alt="sporthorse-app-screen" className="feature-dark-screen-img"/>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm1={6}>
                            <div className="feature-dark-half">
                                <div className="feature-dark">
                                    <h2 className="feature-white-title">Audio Streaming</h2>
                                    <p className="feature-white-text">
                                        Sporthorse features live audio streaming, allowing users to tune into horse-related
                                        radio broadcasts. This innovative feature caters to enthusiasts seeking the latest
                                        news, music, and commentary in the equestrian world.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                )}
            </Container>
        </div>
    );
};

export default DarkFeature1;