import React from 'react';
import {Rating} from "@mui/material";
import tick from '../../assets/images/tick.svg'
import './styles.css'


const Card = ({obj, active}) => {
    return (
        <div>
            <div className={`card ${active ? 'active' : ''}`}
                 style={{backgroundImage: `url(${obj.image})`, backgroundSize: "cover"}}>
                <div className="testimonial-inner">
                    <p className="testimon-name">{obj.name}</p>
                    <Rating name="read-only" value={obj.rating} readOnly className="rating-stars"/>
                    <div className='testimonial-type'><img src={tick} alt="tick"/>{obj.type}</div>
                    <p className="testimonial-text">{obj.text}</p>
                </div>
                <div className="overlay"></div>
            </div>
            <div className="mobile-testimon-text">   <p>{obj.text}</p> </div>
        </div>
    );
};

export default Card;