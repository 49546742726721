import React from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import BlueArrow from '../../assets/images/Blue_arrow.png'
import HandsPic from '../../assets/images/hands-pic.png'
import './styles.css'

const TeamProcess = () => {
    const mobile = useMediaQuery({ maxWidth: 900});
    return (
        <div className="process-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className="white-title">Our Team augmentation process</h2>
                <p className="white-text" style={{textAlign: "center"}}>The process is structured and collaborative.</p>
                {mobile ? (
                    <Grid container justifyContent="space-between" sx={{marginTop:"62px"}}>
                        <Grid item xs={12}>
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Consultation</h2>
                                </div>
                                <p className="white-text">We begin with a detailed consultation to understand your objectives and requirements.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Ongoing support</h2>
                                <p className="black-text">We offer support and communication throughout the project.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Customized solutions</h2>
                                </div>
                                <p className="white-text">We tailor a team augmentation plan to your project's unique needs.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Project execution</h2>
                                <p className="black-text">Our experts work alongside your team to achieve project milestones.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Resource selection</h2>
                                </div>
                                <p className="white-text">Our experts are carefully chosen to match your project's technical requirements.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Integration</h2>
                                <p className="black-text">Seamless integration of our experts into your team ensures a productive working relationship.</p>
                            </div>
                        </Grid>
                    </Grid>
                ):(
                    <Grid container justifyContent="space-between" sx={{marginTop:"62px"}}>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Consultation</h2>
                                </div>
                                <p className="white-text">We begin with a detailed consultation to understand your objectives and requirements.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Ongoing support</h2>
                                <p className="black-text">We offer support and communication throughout the project.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt="" style={{transform:"rotate(-180deg)"}}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Customized solutions</h2>
                                <p className="black-text">We tailor a team augmentation plan to your project's unique needs.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Project execution</h2>
                                </div>
                                <p className="white-text">Our experts work alongside your team to achieve project milestones.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt=""/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt="" style={{transform:"rotate(-180deg)"}}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-dark">
                                <div className="gradient-title-wrapper">
                                    <h2 className="bigger-gradient" style={{marginTop:"0", marginBottom:"22px"}}>Resource selection</h2>
                                </div>
                                <p className="white-text">Our experts are carefully chosen to match your project's technical requirements.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2.2} container justifyContent="center">
                            <div className="arrow-icon-wrapper">
                                <img src={BlueArrow} alt="" style={{transform:"rotate(-90deg)"}}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4.88}>
                            <div className="team-white">
                                <h2 className="black-inner-title">Integration</h2>
                                <p className="black-text">Seamless integration of our experts into your team ensures a productive working relationship.</p>
                            </div>
                        </Grid>
                    </Grid>
                )}

            </Container>
            <div className="process-pic-wrapper">
                <img src={HandsPic} alt=""/>
            </div>
        </div>
    );
};

export default TeamProcess;