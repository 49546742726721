import React from 'react';
import {Container} from "@mui/material";


import './styles.css';
import bgImg from "../../../assets/images/horse-racing.jpg";
import screen1 from "../../../assets/images/sporthorse-1.png";
import screen2 from "../../../assets/images/sporthorse-2.png";
import screen3 from "../../../assets/images/sporthorse-3.png";
import screen4 from "../../../assets/images/sporthorse-4.png";
import GooglePlayIcon from "../../../assets/images/google_play_icon.svg";
import {useMediaQuery} from "react-responsive";
import SliderMobile from "../../SliderMobile/SliderMobile";
import CaseSocialSlider from "../../CaseStudySocial/CaseSocialSlider";


const ProjectScreens = () => {
    const max700 = useMediaQuery({maxWidth: 700});

    return (
        <>
            {max700 ? (
                <div
                    style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(${bgImg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        paddingTop:"30px"
                    }}
                >
                    <div style={{position: "relative", marginTop: "2px"}}>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                            <div className="case-transport-slider" style={{marginBottom: "5px"}}>
                            </div>
                        </Container>
                        <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                            <CaseSocialSlider/>
                        </Container>
                    </div>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="play-market-wrapper-small">
                            <p className="available-on-white">Now available on</p>
                            <div className="info-box-white">
                                <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                <p> Google Play and AppStore</p>
                            </div>
                        </div>
                    </Container>
                </div>
            ) : (
                <div className="project-screens-block"
                     style={{
                         backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(${bgImg})`,
                         backgroundRepeat: "no-repeat",
                         backgroundSize: "cover",
                         backgroundPosition: "center",
                         width: "100%",
                     }}
                >
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div style={{position: "relative"}}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%"
                            }}>
                                <div className="screens-wrapper">
                                    <img src={screen1} alt="screen-1"/>
                                    <img src={screen2} alt="screen-2"/>
                                    <img src={screen3} alt="screen-3"/>
                                    <img src={screen4} alt="screen-4"/>
                                </div>
                            </div>
                            <div className="play-market-wrapper">
                                <p className="available-on-white">Now available on</p>
                                <div className="info-box-white">
                                    <img src={GooglePlayIcon} alt="GooglePlayIcon"/>
                                    <p> Google Play and AppStore</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
        </>

    );
};

export default ProjectScreens;