import React from 'react';
import {CssBaseline} from "@mui/material";
import AppToolBar from "../AppToolBar/AppToolBar";
import Footer from "../Footer/Footer";


const Layout = ({children}) => {
    return (
        <>
            <CssBaseline/>
                <AppToolBar/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{flex: '1 1 auto'}}>
                            {children}
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Layout;