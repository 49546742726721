import React, {useState} from 'react';
import {Container, Grid} from "@mui/material";
import {Formik} from 'formik'
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useLocation} from "react-router-dom";

const ContactUs = () => {

    const [contact, setContact] = useState({
        fullName: "",
        email: "",
        companyName: "",
        phone: "",
        text: "",
    })

    const [agree, setAgree] = useState(false);

    const handleCheckbox = ()=>{
        setAgree(!agree)
    }


    const handleSubmit = ()=>{
        console.log('submit')
    }

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number'),
        companyName: Yup.string(),
        text: Yup.string()
    });


    return (
        <div className="contact-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <div className='contact-box'>
                    <div className={"gradient-title-wrapper"}>
                        {/*{pathname === '/startups' ? (*/}
                        {/*    <div style={{width:"100%", margin:"55px 0 36px"}}>*/}
                        {/*        <h2 className="bigger-gradient" style={{textAlign:"left", margin: '0'}}>Ready to start a project?</h2>*/}
                        {/*        <p className="get-consultation-contact-text">Get consulted by our specialists to validate your project idea</p>*/}
                        {/*    </div>*/}
                        {/*):(*/}
                        {/*    <h2 className="bigger-gradient" style={{textAlign:"center", margin: '62px 0'}}>Start a conversation with us</h2>*/}
                        {/*    */}
                        {/*)}*/}

                        <h2 className="bigger-gradient" style={{textAlign:"center", margin: '62px 0'}}>Start a conversation with us</h2>

                    </div>


                    <Formik
                        initialValues={contact}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({handleSubmit, handleChange, values, errors, touched}) => (
                            <form onSubmit={handleSubmit}>

                                <Grid container justifyContent="space-between">
                                    <Grid item xs={12} md={5.85}>
                                        <input
                                            type={"text"}
                                            name="fullName"
                                            onChange={handleChange}
                                            value={values.fullName}
                                            className={errors.fullName && touched.fullName ? "contact-input error bolder" : "contact-input bolder"}
                                            placeholder={'Full Name *'}
                                        />
                                        {/*{errors.name && touched.name ? (*/}
                                        {/*    <p className="required">{errors.name}</p>*/}
                                        {/*) : null}*/}
                                    </Grid>
                                    <Grid item xs={12} md={5.85}>
                                        <input type={"text"}
                                               name="email"
                                               onChange={handleChange}
                                               value={values.email}
                                               className={errors.name && touched.name ? "contact-input error bolder" : "contact-input bolder"}
                                               placeholder={'Email *'}
                                        />
                                        {/*{errors.email && touched.email ? (*/}
                                        {/*    <p className="required">{errors.email}</p>*/}
                                        {/*) : null}*/}
                                    </Grid>
                                    <Grid item xs={12} md={5.85}>
                                        <input
                                            type={"text"}
                                            name="companyName"
                                            onChange={handleChange}
                                            value={values.companyName}
                                            className={errors.companyName && touched.companyName ? "contact-input" : "contact-input"}
                                            placeholder={'Company Name'}
                                        />
                                        {/*{errors.name && touched.name ? (*/}
                                        {/*    <p className="required">{errors.name}</p>*/}
                                        {/*) : null}*/}
                                    </Grid>
                                    <Grid item xs={12} md={5.85}>
                                        <input type={"text"}
                                               name="phone"
                                               onChange={handleChange}
                                               value={values.phone}
                                               className={errors.phone && touched.phone ? "contact-input" : "contact-input"}
                                               placeholder={'Phone number / Skype ID'}
                                        />
                                        {/*{errors.email && touched.email ? (*/}
                                        {/*    <p className="required">{errors.email}</p>*/}
                                        {/*) : null}*/}
                                    </Grid>

                                    <Grid item xs={12}>
                                            <textarea
                                                placeholder={'How can we help you?'}
                                                className={"contact-input-textarea"}
                                                name={"text"}
                                                value={values.text}
                                                onChange={handleChange}

                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{display: "flex", alignItems: "center", marginTop:"32px"}}>

                                            <input type="checkbox" id={"agree"} className={"checkbox"}
                                                   onChange={handleCheckbox}/>

                                            {agree ? (
                                                <CheckBoxIcon sx={{ color: "#161616"}}/>
                                            ) : (
                                                <CheckBoxOutlineBlankIcon sx={{ color: "#161616" }}/>
                                            )}
                                            <label htmlFor={"agree"} className={"agree-label"}>
                                                I accept your Privacy Policy
                                            </label>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="submit" className="send-btn">Send message</button>
                                    </Grid>
                                </Grid>

                            </form>
                        )}
                    </Formik>
                </div>
            </Container>
        </div>
    );
};

export default ContactUs;