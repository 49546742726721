import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";

import pictureImg from '../../assets/images/pic1-min.jpeg'
import nextIcon from '../../assets/images/span.svg'
import 'swiper/swiper-bundle.css';
import './styles.css'



const SingleSlider = ({sliderId, slides}) => {

    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (swiper) {
            swiper.navigation.update();
        }
    }, [swiper]);

    const handleInit = (swiperInstance) => {
        setSwiper(swiperInstance);
    };

    const handleNextClick = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    return (
        <div className="single-slider-wrapper">
            <Swiper
                spaceBetween={0.5}
                slidesPerView={1}
                loop={true}
                onInit={handleInit}
                // navigation={{
                //     nextEl: `.swiper-button-next-my-${sliderId}`,
                //     // clickable: true,
                // }}
                className={`mySwiper`}
                style={{ width: '100%', height: '100%' }}
            >
                {slides && slides.map((slide, index) =>(
                    <SwiperSlide key={index}>
                        <div style={{width:"100%", height:"100%",
                            backgroundImage: `url(${slide})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat:"no-repeat",
                            borderRadius:"10px",
                        }}
                        >
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
            <div className="single-slider-nav-btn">
                <button onClick={handleNextClick} className={`swiper-button-next-${sliderId}`}><img src={nextIcon} alt="next slide"/></button>
            </div>
        </div>
    );
};

export default SingleSlider;