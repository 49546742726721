import React, {useEffect} from 'react';
import {Container, Grid} from "@mui/material";

import FAQs from "../../components/FAQs/FAQs";
import ChooseUs from "../../components/ChooseUs/ChooseUs";
import LifeCycle from "../../components/LifeCycle/LifeCycle";
import Gettik from "../../components/Gettik/Gettik";
import ProjectIdea from "../../components/ProjectIdea/ProjectIdea";
import GetConsultation from "../../components/GetConsultation/GetConsultation";
import ContactUs from "../../components/ContactUs/ContactUs";

import bgImg2 from "../../assets/images/startup_bg.png";
import './styles.css';


const Startups = () => {

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <div>
            <div style={{paddingTop:"70px", backgroundColor:"#000"}}>
                <div className="startup-inner"
                     style={{
                         backgroundImage: `url(${bgImg2})`,
                         backgroundSize: 'cover',
                         backgroundPosition: "center",
                         backgroundRepeat: "no-repeat",
                     }}
                >

                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                        <div className="startup-hero-section">
                            <h2 className="large-title " style={{marginBottom: "22px"}}>
                                Launch fast and prove the product-market-fit
                            </h2>

                            <p className="small-subtitles space-bottom">slowness in launching an MVP or
                                scaling a released product can kill an early stage startup. Partner with us to get access to
                                top talent, off-load the time-intensive challenges of managing the development life cycle,
                                and free up time so you can focus on growth.</p>

                            <span>Building from scratch or scaling a product?</span>

                            <button className="styled-button">Let's talk</button>
                        </div>
                    </Container>
                </div>
            </div>


            <div className="black-startup-box">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="white-startup-box">
                        <div className="white-startup-box-inner">
                            <Grid container justifyContent="space-around">
                                <Grid item xs={12} sm={2.80}>
                                    <div className="analytics-box">
                                        <p className="analytics-number">4</p>
                                        <p className="analytics-text">Own launched startups</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5.6}>
                                    <div className="analytics-box">
                                        <p className="analytics-number">10 000+</p>
                                        <p className="analytics-text">Daily visits to our services</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2.80}>
                                    <div className="analytics-box">
                                        <p className="analytics-number">+10</p>
                                        <p className="analytics-text">Startups got our help</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Container>
            </div>
            <ChooseUs/>
            <LifeCycle/>
            <Gettik/>
            <ProjectIdea/>
            <GetConsultation/>
            <FAQs/>
            <div style={{paddingTop: "112px", backgroundColor: "#161616"}}>
                <ContactUs/>
            </div>
        </div>
    );
};

export default Startups;