import React from 'react';
import {Container, Grid} from "@mui/material";
import {useMediaQuery} from "react-responsive";

import triangle from "../../../assets/images/Polygon 2.svg";
import flagUk from "../../../assets/images/flag-uk.png";
import avatar from "../../../assets/images/avatart.png";
import avatar2 from "../../../assets/images/avatar.png";
import polygon from "../../../assets/images/Polygon 4.svg";
import linkedIn from "../../../assets/images/linkedIn.svg";
import clutch from "../../../assets/images/clutch.svg";

import './styles.css'

const GettikReviews = () => {
    const max730 = useMediaQuery({maxWidth: 730});
    const max500 = useMediaQuery({maxWidth: 500});

    return (
        <div className="gettik-review-block">
            <div className="gettik-review-triangle"></div>
            <Container maxWidth={false} sx={{maxWidth: "1248px"}}>
                <div className="gettik-review-inner-block">
                    {max730 ? (
                        <>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                position: "relative",
                                minHeight: "340px"
                            }}>
                                <div style={{maxWidth: "681px"}}>
                                    <h2>Our clients say</h2>
                                    <div className="avatar-wrapper-small">
                                        <img src={avatar2} alt="avatar"/>
                                    </div>
                                    <p>
                                        Working with the team was very beneficial, I know I can rely on Dartlab to
                                        deliver us a great
                                        product, but also on educating me on best practices every step of the way
                                    </p>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems='center'
                                        style={{marginTop:"42px"}}

                                    >
                                        <Grid item xs={12} sm1={5}>
                                            <div className="flag-wrapper-box">
                                                <img src={flagUk} alt="flag-uk" className="flag-uk"/>
                                                <div>
                                                    <p>Isa Orunbaev</p>
                                                    <span>CEO of Gettik</span>
                                                </div>

                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm1={5}>
                                            <div
                                                className="avatar-social-links"
                                                style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                                <img src={linkedIn} alt="linkedin" style={{marginRight: '14px'}}/>
                                                <img src={clutch} alt="linkedin"/>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{display: "flex", justifyContent: "space-between",}}>
                                <div style={{maxWidth: "681px"}}>
                                    <h2>Our clients say</h2>

                                    <div className="arrow-up">
                                        <img src={triangle} alt="triangle" style={{marginRight: "10px"}}/>
                                        <img src={triangle} alt="triangle"/>
                                    </div>

                                    <p style={{padding: "0 22px"}}>
                                        Working with the team was very beneficial, I know I can rely on Dartlab to
                                        deliver us a great
                                        product, but also on educating me on best practices every step of the way
                                    </p>

                                    <div className="flag-wrapper-box">
                                        <img src={flagUk} alt="flag-uk" className="flag-uk"/>
                                        <div>
                                            <p>Isa Orunbaev</p>
                                            <span>CEO of Gettik</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="avatar-wrapper">
                                        <div className="avatar-img-wrapper">
                                            <img src={avatar} alt="avatar"/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        marginTop: "14px"
                                    }}>
                                        <img src={linkedIn} alt="linkedin" style={{marginRight: '14px'}}/>
                                        <img src={clutch} alt="linkedin"/>
                                    </div>
                                </div>
                            </div>
                            <img src={polygon} alt="polygon" className="gettik-review-polygon"/>
                            <img src={polygon} alt="polygon" className="gettik-review-polygon1"/>
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default GettikReviews;