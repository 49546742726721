import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import missionImg from "../../assets/images/mission-img.png";
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import './styles.css'

const Statistics = () => {

    const texts1 = [
        {number: "10+", text: "years of experience"},
        {number: "98%", text: "price satisfaction"},
    ];
    const texts2 = [
        {number: "92%", text: "customer recommendation"},
        {number: "10+", text: "years of experience"},
    ];

    const texts3 = [
        {number: "87%", text: "customer loyalty for 2+ years"},
        {number: "92%", text: "customer recommendation"},
    ];

    const texts4 = [
        {number: "98%", text: "price satisfaction"},
        {number: "87%", text: "customer loyalty for 2+ years"},

    ];


    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % texts1.length);
        }, 10000); // change every 4 seconds
        return () => clearInterval(interval);
    }, []);


    const StatisticBox = ({number, text}) => (
        <div className="statistic-box">
            <p className="statistic-number">{number}</p>
            <p className="statistic-text">{text}</p>
        </div>
    );


    return (
        <div className={"statistic"}>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item container xs={12} sm={12} md={12} lg={4.46} sx={{marginBottom: "22px"}}>
                        <div className={'mission-box'}>
                            <div className="gradient-title-wrapper">
                                <h2 className='gradient'>Our mission</h2>
                            </div>
                            <Grid container flexDirection={{sm: "row", lg: "collumn"}}>
                                <Grid item xs={12} md={6} lg={12}>
                                    <p>is to deliver the best possible reliable software solutions to help our clients
                                        improve IT efficiency and business profitability.</p>
                                </Grid>

                                <Grid item xs={12} md={6} lg={12} container justifyContent={"center"}>
                                    <img src={missionImg} alt="missionImg"/>
                                </Grid>
                            </Grid>

                        </div>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12} lg={7.3} justifyContent={"space-between"}>
                        <Grid item xs={5.83} sm={5.83} sx={{marginBottom: "22px"}}>
                            <div className={'statistic-box'}>
                                <TransitionGroup>
                                    <CSSTransition
                                        key={index}
                                        timeout={2000}
                                        classNames="slide"
                                    >
                                        <div className="statistic-content">
                                            <p className="statistic-number">{texts1[index].number}</p>
                                            <p className="statistic-text">{texts1[index].text}</p>
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>

                        </Grid>
                        <Grid item xs={5.83} sm={5.83} sx={{marginBottom: "22px"}}>
                            <div className={"statistic-box"}>
                                <TransitionGroup>
                                    <CSSTransition
                                        key={index}
                                        timeout={2000}
                                        classNames="slide"
                                    >
                                        <div className="statistic-content">
                                            <p className="statistic-number">{texts2[index].number}</p>
                                            <p className="statistic-text">{texts2[index].text}</p>
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </Grid>
                        <Grid item xs={5.83} sm={5.83} sx={{marginBottom: "22px"}}>
                            <div className={"statistic-box"}>
                                <TransitionGroup>
                                    <CSSTransition
                                        key={index}
                                        timeout={2000}
                                        classNames="slide"
                                    >
                                        <div className="statistic-content">
                                            <p className="statistic-number">{texts3[index].number}</p>
                                            <p className="statistic-text">{texts3[index].text}</p>
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </Grid>
                        <Grid item xs={5.83} sm={5.83} sx={{marginBottom: "22px"}}>
                            <div className={"statistic-box"}>
                                <TransitionGroup>
                                    <CSSTransition
                                        key={index}
                                        timeout={2000}
                                        classNames="slide"
                                    >
                                        <div className="statistic-content">
                                            <p className="statistic-number">{texts4[index].number}</p>
                                            <p className="statistic-text">{texts4[index].text}</p>
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </Grid>
                    </Grid>

                </Grid>

            </Container>
        </div>
    );
};

export default Statistics;