import React from 'react';
import {Container, Grid} from "@mui/material";

import './styles.css';

const CycleTab = ({obj}) => {


    return (
            <div className="cycle-content-box">
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} md={7.9} style={{marginBottom:"32px"}}>
                        <h2 className="cycle-content-title">{obj.title1}</h2>
                        <p className="cycle-content-text">{obj.text1}</p>
                    </Grid>
                    <Grid item xs={12} md={3.1}>
                        <h2 className="cycle-content-title">{obj.title2}</h2>
                        {obj.staff.map((member, i) => (
                                <p key={i} className="cycle-content-text">{member}</p>
                            ))}
                    </Grid>
                </Grid>
            </div>
    );
};

export default CycleTab;