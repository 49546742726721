import Layout from "./components/Layout/Layout";
import {Route, Routes} from "react-router-dom";
import Home from "./containers/Home/Home";
import Company from "./containers/Company/Company";
import MobileDev from "./containers/MobileDev/MobileDev";
import WebDev from "./containers/WebDev/WebDev";
import TeamPage from "./containers/TeamPage/TeamPage";
import UX from "./containers/UX/UX";

import './App.css';
import Startups from "./containers/Startups/Startups";
import GettikCase from "./containers/GettikCase/GettikCase";
import SportHorseCase from "./containers/SportHorseCase/SportHorseCase";
import ElmartCase from "./containers/ElmartCase/ElmartCase";
import Oshbus from "./containers/OshbusCase/Oshbus";



function App() {
    return (
        <Layout>
            <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/company" element={<Company/>}/>
                    <Route path="/mobile" element={<MobileDev/>}/>
                    <Route path="/web" element={<WebDev/>}/>
                    <Route path="/team" element={<TeamPage/>}/>
                    <Route path="/ux" element={<UX/>}/>
                    <Route path="/startups" element={<Startups/>}/>
                    <Route path="/gettik-case" element={<GettikCase/>}/>
                    <Route path="/sport-horse-case" element={<SportHorseCase/>}/>
                    <Route path="/elmart-case" element={<ElmartCase/>}/>
                    <Route path="/oshbus-case" element={<Oshbus/>}/>
            </Routes>
        </Layout>
    );
}

export default App;
