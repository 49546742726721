import React from 'react';
import {Container, Grid} from "@mui/material";

import './styles.css'
import {useMediaQuery} from "react-responsive";

const ChooseUs = () => {
    const isMobile = useMediaQuery({ maxWidth: 600});
    const isAbove730 = useMediaQuery({ maxWidth: 730});

    return (
        <div className="choose-us-block">
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <p className="grey-small-text">
                    Accelerate your startup idea with us
                </p>
                <h2 className="choose-us-title"> Why should you choose us as your partners?</h2>

                <Grid
                    container
                    justifyContent="space-between"
                    style={ !isAbove730 ? {marginBottom:"32px"} : {marginBottom:"0"}}
                    flexDirection={ isAbove730 ? "column-reverse" :"row"}
                >
                    <Grid item xs={12} sm1={6.85} style={{alignSelf: "self-end"}}>
                        <div className="choose-black-box" style={{verticalAlign: "bottom"}}>
                            <h3 className=" choose-reason-title  reason-2">
                                02 Speed up
                            </h3>
                            <p className="white-startup-text text-startup-red">
                                We use industry-leading Agile Project Management and Quality Assurance techniques to
                                minimize time consumption.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm1={4.85} >
                        <div className="choose-grey-box">
                            <h3 className="choose-reason-title reason-1">
                                01 Get quick access to tech expertise
                            </h3>
                            <p className="white-startup-text">
                                Our expertise in Flutter, Javascript, Php, Elasticsearch, AWS, GCP and much more will
                                help you avoid long and costly hiring in-house developers.
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between" >
                    <Grid item xs={12} sm1={4.85} style={ isAbove730 ? {marginBottom:"32px"} : {marginBottom:"0"}}>
                        <div className="choose-grey-box">
                            <h3 className="choose-reason-title reason-3">
                                03 Save money
                            </h3>
                            <p className="white-startup-text">
                                Due to its regional location, Dartlab offers affordable hourly rates. For example, in
                                Kyrgyzstan, the average hourly rate for software development companies are between $ 20
                                and $ 40. In the US, this rate ranges from $ 100 to $ 180.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm1={6.85}>
                        <div className="choose-grey-box" style={{verticalAlign: "bottom"}}>
                            <h3 className=" choose-reason-title  reason-4">
                                04 Advanced skills and cultural fit
                            </h3>
                            <p className="white-startup-text">
                                Due to the Soviet IT school, Kyrgyzstan is a powerful IT industry hub with a good higher
                                education, the same as in Ukraine, Belarus and Russia. We share the same values,
                                business paradigms of responsibility, service quality and transparency, as EU and US
                                clients.
                            </p>
                        </div>
                    </Grid>
                </Grid>


            </Container>
        </div>
    );
};

export default ChooseUs;