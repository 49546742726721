import React from 'react';
import {Container, Grid, Hidden} from "@mui/material";
import arrow from '../../assets/images/arrow-right.svg'
import trackingBus from '../../assets/images/busTracking.png'
import SliderMobile from "../SliderMobile/SliderMobile";
import screen from '../../assets/images/iPhone 14 Pro (2).png';
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router-dom";

const CaseStudyTransport = () => {
    const navigate = useNavigate();
    const mediumScreen = useMediaQuery({maxWidth: 1000});
    const isMobile = useMediaQuery({minWidth: 900});
    const mobile = useMediaQuery({maxWidth: 900});
    return (
        <div style={{overflow: "hidden", padding:"20px 0 8px"}}>
            <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                <h2 className='block-title'> Case study</h2>

                <div className="case-transport" style={{ marginBottom: isMobile ? "62px" : "0"}}>
                    <Hidden mdUp>
                        <h3 className='bigger-gradient'>Public transportation tracking app</h3>
                    </Hidden>
                    <Grid container sx={{marginBottom: {xs: "10px", md: "62px"}}}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>

                            <Hidden mdDown>
                                <h3 className='bigger-gradient'>Public transportation tracking app</h3>
                            </Hidden>

                            <p className='case-transport-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Cras
                                sed pulvinar mauris. Vestibulum
                                ac sapien nec quam congue pellentesque. Fusce eu dolor ac turpis rhoncus ornare. Sed
                                eget
                                risus nibh. Nam lacinia enim sed velit bibendum vehicula. Vestibulum ultrices tellus
                                nisl,
                                fringilla pellentesque nisl efficitur ut. In imperdiet, nisi eu bibendum sollicitudin,
                                sapien nisl ornare nulla, et placerat augue risus id metus.</p>

                            <button className='read-more-btn' onClick={()=>navigate('/oshbus-case')}>Read more <img src={arrow} alt="arrow"/></button>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} container justifyContent={'center'} alignItems={"center"}>
                            <div className='tracking-img-wrapper'>
                                <img src={trackingBus} alt="mobile"/>
                            </div>

                            {isMobile && mediumScreen && (
                                <img
                                    src={screen}
                                    alt="screen"
                                    style={{width: "80%", height: "auto%", marginTop: "32px"}}
                                />
                            )}

                        </Grid>
                    </Grid>
                </div>
            </Container>

            {mobile && (
                <div style={{position:"relative"}}>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="case-transport-slider" style={{backgroundColor:"white"}}>
                    </div>
                    </Container>
                    <Container maxWidth={false} sx={{maxWidth: "1284px"}} disableGutters={true}>
                        <SliderMobile/>
                    </Container>
                </div>
            )}
        </div>

    );
};

export default CaseStudyTransport;