import React, {useEffect} from 'react';
import {Container} from "@mui/material";

import Flutter from "../../components/Flutter/Flutter";
import FlutterBenefits from "../../components/FlutterBenefits/FlutterBenefits";
import CaseStudySocial from "../../components/CaseStudySocial/CaseStudySocial";
import DetailedStack from "../../components/DetailedStack/DetailedStack";
import FAQs from "../../components/FAQs/FAQs";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactUs from "../../components/ContactUs/ContactUs";

import birdPic from '../../assets/images/bird-pic.png'
import './styles.css'

const MobileDev = () => {

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <div>
            <div className="mobile-dev-wrapper">
                <Container maxWidth={false} sx={{maxWidth: "1284px"}}>
                    <div className="mobile-dev-text-wrapper">
                        <h2 className="white-title" style={{marginBottom:"12px"}}>
                            Mobile development
                        </h2>
                        <p className="white-text" style={{marginBottom:"0"}}>
                            We specialize in creating cutting-edge mobile applications with a strong emphasis on Flutter development. Our team is dedicated to delivering high-quality, user-friendly mobile solutions that cater to the needs of businesses across various industries.
                        </p>
                        <div className="mobile-dev-pic-wrapper">
                            <img src={birdPic} alt="flutter-bird" style={{marginTop:"-10px"}}/>
                        </div>
                    </div>
                </Container>
            </div>
            <Flutter/>
            <FlutterBenefits/>
            <div className="black-background">
                <CaseStudySocial/>
                <DetailedStack/>
            </div>
            <FAQs/>
            <div className="company-testimonials testimonials" style={{marginBottom:"0", paddingTop:"60px"}}>
                <h3 className="white-title" style={{marginTop:"-10px", marginBottom:"46px"}}>Testimonials</h3>
                <Testimonials/>
            </div>
            <div style={{backgroundColor:"#161616"}}>
                <ContactUs/>
            </div>
        </div>
    );
};

export default MobileDev;