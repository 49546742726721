import React, {useEffect} from 'react';
import '../styles.css'
import MainBlock from "../../components/MainBlock/MainBlock";
import Statistics from "../../components/Statistics/Statistics";
import Offer from "../../components/Offer/Offer";
import Expertise from "../../components/Expertise/Expertise";
import Services from "../../components/Services/Services";
import CaseStudyTransport from "../../components/CaseStudyTransport/CaseStudyTransport";
import TechStack from "../../components/TechStack/TechStack";
import Clients from "../../components/Clients/Clients";
import CaseStudySocial from "../../components/CaseStudySocial/CaseStudySocial";
import Phases from "../../components/Phases/Phases";
import CaseStudyMarket from "../../components/CaseStudyMarket/CaseStudyMarket";
import Testimonials from "../../components/Testimonials/Testimonials";
import ContactUs from "../../components/ContactUs/ContactUs";


const Home = () => {

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    return (
        <div style={{backgroundColor:"#F5F5F7"}}>
            <MainBlock/>
            <Statistics/>
            <Offer/>
            <Expertise/>
            <Services/>
            <CaseStudyTransport/>
            <TechStack/>
            <Clients/>
            <CaseStudySocial/>
            <Phases/>
            <CaseStudyMarket/>
                <div className="testimonials" style={{marginBottom:"30px"}}>
                        <div className="gradient-title-wrapper" style={{marginBottom:"18px"}}>
                                <h3 className="gradient">Testimonials</h3>
                        </div>
                        <Testimonials/>
                </div>
            <div style={{paddingTop:"30px"}}>
                <ContactUs/>
            </div>
        </div>

    );
};

export default Home;